// Define TypeScript interfaces
interface ApiUrls {
  CISCO: string;
  ALE: string;
  MITEL: string;
  HIK: string;
  YEASTAR: string;
}

interface Config {
  API_HOST_URL:string;
  API_URL_IDS: { [key: string]: string };
  STRIPE_PUBLIC_KEY: string;
  OFFERS: { name: string; stripeID: string }[];
}

export async function getConfig(): Promise<Config> {
  const response = await fetch("/config.json");

  if (response.status > 299 || !response.ok) {
    throw Error(response.statusText || "Unknown error");
  }

  return await response.json() as Config;
}

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { ChatProvider } from "../../contexts/ChatProvider";
import Nav from '../../components/UI/Nav/Nav';
import Footer from "../UI/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function Layout () {
    const location = useLocation();
    const {i18n}=useTranslation();
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);


    // Use useEffect to initialize the language when the component mounts
    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        }
    }, []);




    if(isLoggedIn && location.pathname=='/')
    return <Navigate to="/dashboard" />;
        
    return (
    <ChatProvider >
        <div className="nk-mask  bg-blend-bottom"></div>
        <header className="nk-header is-dark has-mask ">
            {!(["/signin", "/signup","/signupcompletion", "/resetpassword", "/password-change" ].includes(location.pathname))&& <Nav/>}
        </header>
        <main className="nk-pages flex-grow-1">
            <Outlet/>
        </main>
        <Footer/>
    </ChatProvider>

    )

};

import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'; // Import useHistory from react-router-dom
import LOGO_IMG from '../../assets/images/logo-no-text.png'
import { useTranslation } from 'react-i18next';
import { useAsyncLoader } from '../../hooks/useAsyncLoader';
import { resetPasswordService } from '../../services/users.service';

export default function PassChange() {
    const { t } = useTranslation(); //  access to translation functions in the application.
    const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle password visibility
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false); // State to toggle password visibility
    const {handler,errors, isLoading} = useAsyncLoader();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token= params.get('token');
    const [passwords, setPasswords] = useState({ // State to set the passwords values in the edit form  
        new_password: '',
        confirm_new_password: '',
      });
    const isDisabled= passwords.new_password!==passwords.confirm_new_password || passwords.confirm_new_password=='' || passwords.new_password=='' //  disabled status of the  updateButton


      const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
      };
    
      const toggleConfirmPasswordVisibility = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
      };
    

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
          setPasswords({ ...passwords, [name]: value });     
      };

      const handleResetPassword = (password_one: string, password_two: string, token:string|null)=> {
        handler(()=> resetPasswordService(password_one, password_two, token))
        .then()
        .catch()
      }


  return (
    <>
        {/* {isLoading && <Spinner/>} */}
        <section className='section'>
            <div className="nk-shape bg-shape-blur-a  position-fixed start-50 top-50 translate-middle"></div>
            <div className="container">
                <div className="section-head pb-5">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-11 col-xl-10 col-xxl-9">
                            <Link to={'/'} className='allianceLogoLink' >
                                <img className="h-5rem mb-3 " src={LOGO_IMG}  alt=""/>
                            </Link>   
                            <h4 className="title">AllianceCom </h4>
                            <h3 className="title">{t("password-reset.title")}</h3>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row g-gs justify-content-center">
                        <div className="col-md-12 col-lg-7 col-xl-5">
                            <div className="card border-0 shadow-sm rounded-4">
                                <div className="card-body">
                                    <form className=' d-flex flex-column gap-5 mt-2' onSubmit={()=>handleResetPassword(passwords.new_password, passwords.confirm_new_password, token)}>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="new_password">{t("userProfile.tab2.new-pwd")}</label>
                                                <div className="form-control-wrap">
                                                    <Link  to="#" onClick={toggleNewPasswordVisibility} className={`form-control-icon end password-toggle ${showNewPassword ? ' is-shown' : ''} `}  title="Toggle show/hide password">
                                                    <em className="on icon ni ni-eye text-base"></em>
                                                    <em className="off icon ni ni-eye-off text-base"></em>
                                                    </Link>
                                                    <input
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    id="new_password" 
                                                    name="new_password"
                                                    value={passwords.new_password}
                                                    onChange={handleChange}
                                                    className="form-control form-control-lg"
                                                    placeholder={t("userProfile.tab2.new-pwd-placeholder")}
                                                    required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="confirm_new_password">{t("userProfile.tab2.confirm-new-pwd")}</label>
                                                <div className="form-control-wrap">
                                                    <Link  to="#" onClick={toggleConfirmPasswordVisibility} className={`form-control-icon end password-toggle ${showConfirmNewPassword ? ' is-shown' : ''} `}  title="Toggle show/hide password">
                                                    <em className="on icon ni ni-eye text-base"></em>
                                                    <em className="off icon ni ni-eye-off text-base"></em>
                                                    </Link>
                                                    <input
                                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                                    id="confirm_new_password" 
                                                    name="confirm_new_password"
                                                    value={passwords.confirm_new_password}
                                                    onChange={handleChange}
                                                    className="form-control form-control-lg"
                                                    placeholder={t("userProfile.tab2.confirm-new-pwd-placeholder")}
                                                    required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-block" type="submit" disabled={isDisabled}>{t("buttons.update")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12">
                                {/* <AlertBox color='danger' message={"qsdqsd"} visibility={true}/> */}
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>

   
    </>
  )
}

import * as types from '../actions/actionsTypes';

// Define the shape of the authentication state
interface AuthState {
  userData: any | null; // Update the type as per your user object structure
  isLoggedIn: boolean;
}

// Initial state for authentication
const initialState: AuthState = {
  userData: null,
  isLoggedIn: false,
  // You can add other relevant properties here
};

const authentication = (state: AuthState = initialState, action: any) => {
  switch (action.type) {
    case types.SIGNIN:
      // Handle the SIGNIN action
      return {
        ...state,
        userData: action.payload.user,
        isLoggedIn: true,
      };
    case types.SIGNUP:
      // Handle the SIGNUP action
      return {
        ...state,
        userData: {},
        isLoggedIn: false,
      };
      
      default:
        return state;
    }
};

export default authentication;
import Box from '../../components/UI/Box/Box'
import Card from '../../components/UI/Card/Card'
import Section from '../../components/UI/Section/Section'
import PRODUCT_IMG from '../../assets/images/icon/product-discription.svg';
import EDIT_IMG from '../../assets/images/icon/edit.svg';
import PAPER_IMG from '../../assets/images/icon/paper.svg';
import A_IMG from '../../assets/images/steps/brands.png';
import B_IMG from '../../assets/images/steps/ideogram.png';
import C_IMG from '../../assets/images/steps/bot.png';
import React from "react";
import Hero from '../../components/UI/Hero/Hero';
import Plans from '../../components/UI/Plans/Plans';
import Accordion from '../../components/UI/Accordion/Accordion';
import { useTranslation } from 'react-i18next';

export default function Home() {
    const { t } = useTranslation();
    
    const CARDS=[
        {   title:`${t('home.section_1.box_1.title')}`,
            description:`${t('home.section_1.box_1.paragraph')}`,
            iconName:'chat-msg-fill',
            color:'info'
        },
        {   title:`${t('home.section_1.box_2.title')}`,
            description:`${t('home.section_1.box_2.paragraph')}`,
            iconName:'clock',
            color:'primary'
        },
        {   title:`${t('home.section_1.box_3.title')}`,
            description:`${t('home.section_1.box_3.paragraph')}`,
            iconName:'award',
            color:'indigo'
        },
        {   title:`${t('home.section_1.box_4.title')}`,
            description:`${t('home.section_1.box_4.paragraph')}`,
            iconName:'security',
            color:'success '
        },
        {   title:`${t('home.section_1.box_5.title')}`,
            description:`${t('home.section_1.box_5.paragraph')}`,
            iconName:'spark',
            color:'danger'
        },
        {   title:`${t('home.section_1.box_6.title')}`,
            description:`${t('home.section_1.box_6.paragraph')}`,
            iconName:'setting',
            color:'warning'
        },
    ];

    const BOXES= [
        //Box: Choose your vendor 
        {
            imgUrl:PRODUCT_IMG,
            stepImgUrl:A_IMG,
            title:`${t('home.section_2.box_1.title')}`,
            description:'',
            items:{
                icon: 'ni-check-circle-fill',
                color: 'info',
                list: [
                    `${t('home.section_2.box_1.dot_1')}`,
                    `${t('home.section_2.box_1.dot_2')}`,
                    `${t('home.section_2.box_1.dot_3')}`,
                    `${t('home.section_2.box_1.dot_4')}`,
                ],
            },
            color:'primary'
        },

        //Box: Describe your problem
        {
            imgUrl:EDIT_IMG,
            stepImgUrl:B_IMG,
            title:`${t('home.section_2.box_2.title')}`,
            description:'',
            items:{
                icon: 'ni-check-circle-fill',
                color: 'info',
                list: [
                    `${t('home.section_2.box_2.dot_1')}`,
                    `${t('home.section_2.box_2.dot_2')}`,
                    `${t('home.section_2.box_2.dot_3')}`,
                ]
            },
            color:'warning'
        },

        //Box: Chat with our AI
        {
            imgUrl:PAPER_IMG,
            stepImgUrl:C_IMG,
            title:`${t('home.section_2.box_3.title')}`,
            description:'',
            items:{
                icon: 'ni-check-circle-fill',
                color: 'info',
                list: [
                    `${t('home.section_2.box_3.dot_1')}`,
                    `${t('home.section_2.box_3.dot_2')}`,
                    `${t('home.section_2.box_3.dot_3')}`,
                    `${t('home.section_2.box_3.dot_4')}`,
                ],
            },
            color:'info'
        },
    ];

    const ACCORDIONS =[
        {   title:`${t("home.section_4.qst_1.title")}`,
            description:`${t("home.section_4.qst_1.text")}` 
        },
        {   title:`${t("home.section_4.qst_2.title")}`,
            description:`${t("home.section_4.qst_2.text")}` 
        },
        {   title:`${t("home.section_4.qst_3.title")}`,
            description:`${t("home.section_4.qst_3.text")}` 
        },
        {   title:`${t("home.section_4.qst_4.title")}`,
            description:`${t("home.section_4.qst_4.text")}` 
        },
    ];

    return (
        <>
            <Hero/>
            <Section 
                id="service" 
                title={t('home.section_1.title')}
                lead={t('home.section_1.lead')}
                classNames="section bg-darker is-dark has-shape"
            >
                {CARDS.map((card,index)=>
                    <React.Fragment key={index}>
                        <Card title={card.title} description={card.description} iconName={card.iconName} color={card.color}/>
                    </React.Fragment>
                )}
            </Section>

            <Section 
                id="howto" 
                title={t('home.section_2.title')}
                lead={t('home.section_2.lead')}
                classNames="section  bg-white rounded-top-6"
            >
                {BOXES.map((box,index)=>
                    <React.Fragment key={index}>
                        <Box imgUrl={box.imgUrl} stepImgUrl={box.stepImgUrl} title={box.title} description={box.description} color={box.color} items={box.items}  />
                    </React.Fragment>
                )}
            </Section>

            <Section 
                id="pricing" 
                title={t('home.section_3.title')}
                lead={t('home.section_3.lead')}
                classNames="section bg-dark rounded-top-5"
            >
                <Plans/>
            </Section>

            <Section
                id="faq" 
                title={t('home.section_4.title')}
                lead={t('home.section_4.lead')}
                classNames="section bg-light"
            >
                <div className="row justify-content-center text-center">
                    <div className="col-xl-9 col-xxl-8">
                        <div className="accordion accordion-separated accordion-plus-minus" id="faq-1">
                            {
                                ACCORDIONS.map((accordion,index)=><Accordion key={index} title={accordion.title} description={accordion.description} index={index+1}/>)
                            }
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}

import { SparkleFilled  } from '@fluentui/react-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { Link } from 'react-router-dom';
import { useChat } from '../../../contexts/ChatProvider';
import useLoggedinUser from '../../../hooks/useLoggedinUser';
import { useTranslation } from 'react-i18next';

export default function OrgMemberDashboard() {
  const {t} = useTranslation();
  const { setActiveChat } = useChat();
  const { USER }= useLoggedinUser();

  const handleNavigation =()=>{
    setActiveChat('ALE')
      window.localStorage.setItem('activeChat', 'ALE');
  }

  return ( 
    (USER.hasAssignedLicence)
    ? <section className='section'>                    
        <div className='text-center'>
          <div className='w-100 text-center'>
          <SparkleFilled fontSize={"120px"} primaryFill={"rgba(8,160,198,1)"} aria-hidden="true" aria-label="Chat logo" />
          </div>
          <h2 className='chatEmptyStateTitle text-center'>{t("users-dashboards.start")}</h2>
          <h1 className='chatEmptyStateTitle text-center'>{t("users-dashboards.ask")}</h1>
          <Link  to='/chat' onClick={handleNavigation} className='btn btn-primary btn-lg rounded-pill mt-5'>{t("users-dashboards.discussion")}</Link>
        </div>
      </section>
    : <section className='section  container d-flex justify-content-start w-100 '>                    
      <div className='chatEmptyState'>
        <div className='w-100 '>
        </div>
        <h3 className='chatEmptyStateTitle '>{t("users-dashboards.no-license")}</h3>
        <h3 className='chatEmptyStateTitle '>{t("users-dashboards.contact-admin")}</h3>
      </div>
      </section>
  )
}

import { combineReducers } from "redux";
import auth from "./authentication";
import users from "./users";
import licences from "./licences";
import subscriptions from "./subscriptions";
import { LOGOUT } from "../actions/actionsTypes";
import storage from "redux-persist/lib/storage";


export const rootReducer = combineReducers({ auth, licences, users ,subscriptions });

const resettableReducer = (state:any, action:any) => {
    if (action.type === LOGOUT) {
      storage. removeItem('persist:root');  
      state = undefined; // Reset all reducers to their initial state
    }
    return rootReducer(state, action);
  };
  
  export default resettableReducer;
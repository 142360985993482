import React, {useContext, useState} from 'react'
import {useSelector} from 'react-redux';
import {RootState} from '../../redux';
import { createSubscriptionService } from '../../services/subscription.service';
import {useNavigate} from 'react-router';
import Section from '../../components/UI/Section/Section';
import {GlobalContext} from "../../contexts/global";
import { generateUniqueId } from '../../utils/index';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const selectOptions =  [
  {
    name:"Pack pro", 
    price:9.5
  },
]

export default function ShoppingCart() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [quantity, setQuantity ] = useState('1')
  const [selectedPlan, setSelectedPlan] = useState('Pack pro');
  const accessToken = useSelector((state: RootState) => state.auth.userData.access_token)

  const { config } = useContext(GlobalContext)
  const OFFERS = config.OFFERS


// Use the map function to generate the select options
const selectPalnOptions = selectOptions.map((option:{name:string, price: number}) => (
  <option key={generateUniqueId()} value={option.name}>
    {option.name}
  </option>
));

  const handleChangeSelectedPlan = (e:React.ChangeEvent<HTMLSelectElement>) =>{
    const { value } = e.target;
      setSelectedPlan(value);
  }

  const handleChangeQuantity =(e:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuantity(String(value));
  }

  const getTotal=(selectedPlan:string, quantity:number )=>{
    const plan = selectOptions.find((item) => item.name === selectedPlan);

    if (plan) {
      const total = plan.price * quantity;
      return total;
    } else {
      return "N/A"; 
    }
  }

  const handlePalceOrder = ()=>{
    const stripe_product_id = selectedPlan=="Pack pro" ? OFFERS[0]['stripeID']
    // in case there are other products we add them to the  config and modify the  selectOptions list with the product names and prices and 
    : selectedPlan=="PREMIUM" 
    ? OFFERS[1]['stripeID']
    :  OFFERS[2]['stripeID'] ;
      
    createSubscriptionService(stripe_product_id,quantity,accessToken)
    .then(response => {
      navigate('/checkout',{
        state:{
          clientSecret:response.client_secret,
        }
      });
    })
    .catch(error => {
      throw(error);
    })
  }

  return (
      <section className="section">
        <div className="container">
        <h2 className="title mb-5">{t('shoping-cart.title')}</h2>
        <form className='bg-dark has-mask p-6 rounded-4 mt-2 '>
          <div className="checkout__box">          
            <div className="">
              <div className="row g-gs justify-content-start">
                <div className="col-6 col-md-8 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">{t('shoping-cart.plan')}</label>
                    <select 
                      id="paln" 
                      name="plan"
                      className="form-control form-control-lg"
                      onChange={handleChangeSelectedPlan} 
                      value={selectedPlan}
                    >
                      {selectPalnOptions}
                    </select>
                  </div>
                </div>
                <div className="form-group col-2 col-md-3 col-sm-12">
                  <label className="form-label" >{t('shoping-cart.quantity')}</label>
                  <div className="form-control-wrap">
                      <input 
                      type="number" 
                      name="quantity"  
                      id='quantity'
                      className="form-control form-control-lg" 
                      min={1}
                      value={quantity}
                      onChange={handleChangeQuantity}
                      disabled
                      />
                  </div>
                </div>
              </div>

              <hr/>

              <h4 className="price w-100 d-flex justify-content-between align-items-center">
                <span className="title m-2">{t('shoping-cart.total')}</span>
                <span className="h3">{ getTotal(selectedPlan, Number(quantity))} €</span>
              </h4>
              
            </div>

          </div>

          <div className='d-flex flex-row justify-content-between mt-5'>
            <Link to="" onClick={handlePalceOrder} className='btn  btn-primary rounded-pill  ' type='submit' >{t('buttons.subscribe')}</Link>
            <Link to='/dashboard' className='btn btn-md btn-danger rounded-pill' >{t('buttons.cancel')}</Link>

          </div>
        </form> 
        </div>
      </section>
  )
  }
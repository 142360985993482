import React, { useState } from 'react'

/**
 * Box Component
 * @param {number} key - Accordion Title.
 * @param {string} title - Accordion Title.
 * @param {string} description - Accordion Description.
 */

// //  Interface for  Box props
interface AccordionProps {
    index:number;
    title: string;
    description: string;
  }
  
export default function Accordion({index, title,description}:AccordionProps) {
    const [isCollapsed , setIsCollapsed] =useState<boolean>(false);

    const handleCollapseAccordion = ()=>{
        setIsCollapsed(prev=>!prev)
    }

  return (
    <div className="accordion-item">
        <h2 className="accordion-header">
            <button className={` accordion-button ${isCollapsed ? '' : 'collapsed'}`} onClick={handleCollapseAccordion} aria-expanded={!isCollapsed} data-bs-toggle="collapse" data-bs-target={`#faq-1-${index}`}  >{title}</button>
        </h2>
        <div id={`faq-1-${index}`} className={` accordion-collapse collapse ${isCollapsed ? ' show' : ''}`} data-bs-parent="#faq-1">
            <div className="accordion-body">{description}</div>
        </div>
    </div> 
 )
}

//  Generate a Unique ID (can be used for assigning unique key during mapping list of HTML elements)
export function generateUniqueId(): string {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}


//  check if the email address is valid 
export function isEmailValid (input: string): boolean  {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};

//  check if password and confirm password Matches
export function isPasswordsMatch (str1: string , str2:string): boolean  {
  return str1==str2;
};


//  check if password and confirm password Matches
export function isPasswordValid (str1: string , str2:string): boolean  {
  return str1==str2;
};

// check the Password weakness 
export function isWeakPassword(password: string): boolean {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

  const isWeak =
    password.length < minLength ||
    !hasUppercase ||
    !hasLowercase ||
    !hasNumber ||
    !hasSpecialCharacter;

  return isWeak; 
}

// getPassword strength variables 
export function getPasswordStrengh(password: string) {
  const length = password.length >=8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
  const nbValid = [length,hasLowercase && hasUppercase, hasNumber, hasSpecialCharacter].filter(value => value === true).length;
  return { length, hasLowercase, hasUppercase, hasNumber, hasSpecialCharacter, nbValid }; 
}

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Footer() {
  const {t} =  useTranslation();
  return (
    <footer className="nk-footer">
      <div className="bg-white is-dark">
        <div className='container'>
          <hr className="border-opacity-25 border-primary m-0"/>
          <div className="container">
            <div className="py-4" >
                <div className="row">
                    <div className="col-md">
                        <p className="mb-2 mb-md-0 w-100 "><Link to="https://groupe-alliance.com/"  target="_blank" className="fw-bold text-base me-3"> Alliance-Com</Link>Copyright 2023</p>
                    </div>
                    <div className="col-md">
                        <ul className="list list-row gx-4 justify-content-start justify-content-md-end">
                            <li><Link to="/terms-of-service" target="_blank" className="link-primary">{t("footer.terms")}</Link></li> 
                            <li><Link to="/privacy-policy" target="_blank" className="link-primary">{t("footer.privacy-policy")}</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

import {AppDispatch} from "../redux";
import axios from "axios";
import { URL } from "../api/constants";
import { logout, signin, signup } from "../redux/actions";
import { getConfig } from "./config.service";

// Signin Service
export const loginService = (credentials:{username:string, password:string}) => async (dispatch: AppDispatch) => {

  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL

  await axios.post(API_HOST_URL+URL.API_LOGIN, credentials, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  .then((response)=>{
    dispatch(signin(response.data));
    return response.data;
  })
  .catch ((error:any)=> {
    if (error.response) {
      // Server returned an error response
      throw error.response.data.detail;
    } else {
      // Network error or other issues
      throw error.message;
    }
  });
}

// Signup Service
export const signupService = (userData:any) => async (dispatch: AppDispatch) => {
  
  let config = await getConfig();
  let API_HOST_URL = config.API_HOST_URL

  await axios.post(API_HOST_URL+URL.API_SIGNUP, userData, {
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then((response)=>{
    dispatch(signup());
  })
  .catch ((error:any)=> {
    if (error.response) {
      // Server returned an error response
      throw error.response.data.detail;
    } else {
      // Network error or other issues
      throw error.message;
    }
  });
}

//Logout Service
export const logoutService = () => async (dispatch: AppDispatch) => {
  return dispatch(logout());
};



import BANNER_IMG from '../../../assets/images/gfx/banner/c.jpg';
import TECHNICIAN_IMG from '../../../assets/images/avatar/illustration/a.png';
import SUPPORT_IMG from '../../../assets/images/avatar/illustration/b.png';
import ENGINEER_IMG from '../../../assets/images/avatar/illustration/c.png';
import EXPERT_IMG from '../../../assets/images/avatar/illustration/d.png';
import MITEL_IMG from '../../../assets/images/brands/mitel.svg';
import ALE_IMG from '../../../assets/images/brands/ale.svg';
import HIKVISION_IMG from '../../../assets/images/brands/hikvision.svg';
import ZYXEL_IMG from '../../../assets/images/brands/zyxel.svg';



import { Link } from 'react-router-dom';
import Typewriter from '../Typewriter/Typewriter';
import { useTranslation } from 'react-i18next';

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div className="nk-hero py-xl-5 overflow-hidden has-shape " id='home'>
      <div className="nk-shape bg-shape-blur-b mt-n5 start-50 top-50 translate-middle-x2"></div>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-11 col-xl-10 col-xxl-9">
            <div className="nk-hero-content py-5 py-lg-6">
                <h1 className="title mb-3 mb-lg-4 display-6 fs-1">
                  {t('home.hero.title')}
                  <div className="text-gradient-primary d-flex justify-content-center">
                  <Typewriter words={['Alcatel-Lucent Enterprise','HIKVISION','MITEL','ZYXEL']}  interval={2000} />
                    <span className="typed-cursor" aria-hidden="true">  
                      |</span></div>
                </h1>
                <p className="lead px-md-8 px-lg-6 px-xxl-12 mb-4 mb-lg-5">{t('home.hero.paragraph')}</p>
                <ul className="btn-list ">
                    <li><Link to="/signup" className="btn btn-primary btn-lg rounded-pill"><span>{t('home.hero.button')}</span></Link></li>
                    <li>
                      <div className="nk-hero-content py-6">
                        {/* <h6 className="lead-text">supported by the official documentation of our partners</h6> */}
                        <ul className="d-flex flex-wrap justify-content-center align-items-center pt-4 has-gap gy-3">
                          <li className="px-3 px-sm-5">
                            <img className="h-4rem" src={ALE_IMG} alt="Alcatel-Lucent Enterprise logo"/>
                          </li>
                          <li className="px-3 px-sm-5">
                            <img className="h-2rem" src={HIKVISION_IMG} alt="HIKVISION logo"/>
                          </li>
                          <li className="px-3 px-sm-5">
                            <img className="h-2rem" src={MITEL_IMG} alt="MITEL logo"/>
                          </li>
                          <li className="px-3 px-sm-5">
                            <img className="h-2rem" src={ZYXEL_IMG} alt="ZYXEL logo"/>
                          </li>
                        </ul>
                      </div>
                    </li>
                </ul>

            </div>
            <div className="nk-hero-gfx position-relative">
                <img className="w-100 rounded-4" src={BANNER_IMG}alt=""/>
                <div className="d-none d-md-block position-absolute top-0 end-100 me-5 me-lg-8 me-xl-12 mt-n3">
                    <div className="media media-2xl rounded-pill mx-auto">
                        <img src={TECHNICIAN_IMG} alt=""/>
                    </div>
                    <div className="badge bg-dark p-2 mt-2 fw-normal text-white text-opacity-75">{t('home.hero.technicians')}</div>
                </div>
                <div className="d-none d-md-block position-absolute top-50 end-100 me-3 me-lg-4 mt-n5">
                    <div className="media media-2xl rounded-pill mx-auto">
                        <img src={EXPERT_IMG} alt=""/>
                    </div>
                    <div className="badge bg-dark p-2 mt-2 fw-normal text-white text-opacity-75">{t('home.hero.experts')}</div>
                </div>
                <div className="d-none d-md-block position-absolute top-0 start-100 ms-5 ms-lg-7 ms-xl-10 mt-n7">
                    <div className="media media-2xl rounded-pill mx-auto">
                        <img src={SUPPORT_IMG} alt=""/>
                    </div>
                    <div className="badge bg-dark p-2 mt-2 fw-normal text-white text-opacity-75">{t('home.hero.p-support')}</div>
                </div>
                <div className="d-none d-md-block position-absolute top-50 start-100 ms-4 ms-lg-5 mt-n2">
                    <div className="media media-2xl rounded-pill mx-auto">
                        <img src={ENGINEER_IMG} alt=""/>
                    </div>
                    <div className="badge bg-dark p-2 mt-2 fw-normal text-white text-opacity-75">{t('home.hero.engineers')}</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

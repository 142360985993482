import React, { useState, useEffect } from 'react';

interface TypewriterProps {
    words: string[];
    interval: number;
}


const Typewriter = ({ words, interval }:TypewriterProps) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [currentText, setCurrentText] = useState('');
  
    useEffect(() => {
      const wordDisplayInterval = setInterval(() => {
        if (currentText.length < words[currentWordIndex].length) {
          setCurrentText(words[currentWordIndex].substring(0, currentText.length + 1));
        } else {
          clearInterval(wordDisplayInterval); // Stop typing
          setTimeout(() => {
            setCurrentText(''); // Clear text after 2 seconds
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
          }, interval);
        }
      }, 100);
  
      return () => clearInterval(wordDisplayInterval);
    }, [currentText, currentWordIndex, words, interval]);
  

  return (
    <div>
      <h1>{currentText}</h1>
    </div>
  );
};

export default Typewriter;

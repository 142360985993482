import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handlePayment = async (event:  React.FormEvent)=>{
    event.preventDefault();

    if (!stripe) {
      return;
    }

    if(elements != null && stripe) {
      await stripe.confirmSetup(
      //`Elements` instance that was used to create the Payment Element
      {
        elements: elements,
        confirmParams  : {
          return_url: `${document.location.origin}/payment-success`,
        }
      }
    ).then(response=>{
      console.log('Payment confirmed >>> ',response);
      }).catch(error =>{
        throw(error);
      })
    }
  }

  return (
    <div className=' container'>
      <form  className='payment-form'>
        <h4 className="title mb-4">{t("checkout-form.title")}</h4>
        <PaymentElement />
        <button className='btn btn-primary rounded-pill mt-3' type="submit" disabled={!stripe || !elements} onClick={handlePayment}>
          {t("buttons.confirm-payment")}
        </button>
      </form>
    </div>
  )
}

import React from 'react'
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

// Create a PrivateRoute component to protect routes
export default function PrivateRoute({ element }: { element: React.ReactNode }) {
  
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  
  if (!isLoggedIn) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/signin" />;
  }
  return <>{element}</>;

}

import { Navigate } from 'react-router-dom';
import Chat from '../../components/Chat/Chat'
import useLoggedinUser from '../../hooks/useLoggedinUser';
import React, { useEffect } from "react";

export default function Chatbot() {

  const { USER } = useLoggedinUser();


  const [currentChat, setCurrentChat] = React.useState('ALE');

  window.setInterval(() => {
    const activeChat = window.localStorage.getItem('activeChat');
    if (activeChat !== currentChat && activeChat) {
      setCurrentChat(activeChat);
    }
  }, 500);

  let chatIframe
  if(currentChat === 'ALE'){
     chatIframe = <iframe style={{width : "100%", height:"100vh" , zIndex:"-1"}} src="https://app-backend-smcqpj5meoptq.azurewebsites.net"></iframe>
  }
  if(currentChat === 'MITEL'){
    chatIframe = <iframe style={{width : "100%", height:"100vh" , zIndex:"-1"}}  src="https://app-backend-srbvytm65ptze.azurewebsites.net"></iframe>
  }
  if(currentChat === 'HIKVISION'){
    chatIframe = <iframe style={{width : "100%", height:"100vh" , zIndex:"-1"}}  src="https://app-backend-xoz4pggdih3ni.azurewebsites.net"></iframe>
  }
  if(currentChat === 'ZYXEL'){
    chatIframe = <iframe style={{width : "100%", height:"100vh" , zIndex:"-1"}}  src="https://app-backend-wap3usnu7qbmm.azurewebsites.net"></iframe>
  }

  if (USER.isAuthorized)
    return (
        <div>
          {chatIframe}
        </div>

    )
  return <Navigate to="/forbidden" />;

}

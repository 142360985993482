import React from 'react'

/**
 * Composant Card
 * @param {string} title - Card Title.
 * @param {string} description - Card Descirption.
 * @param {string} iconName - Icon Name - to import iconShape (to be contcatinated to the iconclass:  ni-${iconName}).
 * @param {string} color - icon color and its container bg.
 */

//  Interface for  Card props
interface CardProps {
  title:string;
  description:string;
  iconName:string;
  color:string
}

export default function Card({title,description,iconName, color}:CardProps){
  return (
    <div className="col-md-6 col-xl-4">
      <div className="card rounded-4 border-0 h-100">
        <div className="card-body">
            <div className="feature">
                <div className="feature-media">
                    <div className={`media media-middle media-xl text-${color} bg-${color} bg-opacity-20 rounded-3`}>
                       <div className=" d-flex align-items-end">
                          <em className={`icon ni ni-${iconName}`}></em>
                          {(iconName=='text') && <em className="icon half ms-n2 ni ni-text"></em>}
                        </div>
                    </div>
                </div>
                <div className="feature-text">
                    <h4 className="title">{title}</h4>
                    <p>{description}</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import Item from '../Item/Item';

/**
 * Box Component
 * @param {string} imgUrl - Box Image.
 * @param {string} stepImgUrl - Box Step Image.
 * @param {string} title - Box Title.
 * @param {string} description - Box Descirption.
 * @param {{icon:string, list:string[], color:string}} items - Items Objects that contains the item Icon and list of items .
 * @param {{icon:string, list:string[]}} color - Items Objects that contains the item Icon and list of items .
 * @param {string} ImgUrl - Url de l'image du Box.
 */

// //  Interface for  Box props
interface BoxProps {
  imgUrl: string;
  stepImgUrl: string;
  title:string;
  description:string;
  items:{icon:string, list:string[], color:string};
  color:string;
}

export default function Box({imgUrl, stepImgUrl, title,description, items, color}:BoxProps) {
  return (
      <div className={`bg-${color} bg-opacity-10 p-6 rounded-4` }>
          <div className="row g-gs justify-content-between align-items-stretch">
              <div className="col-lg-7 ">
                  <div className="block-text pe-xl-5">
                      <img className="h-3rem mb-3" src={imgUrl}  style={{height:'50px', width:'50px'}} alt=""/>
                      <h3 className="title">{title}</h3>
                      <p>{description}</p>
                      <ul className="list gy-3">
                        {items.list.map((item,index) =>
                            <React.Fragment key={index}>
                                <li><Item key={index} title={item} icon={items.icon} color={items.color}/></li>
                            </React.Fragment>
                        )}
                          {/* <li><em className="icon text-info fs-5 ni ni-check-circle-fill"></em><span>5 vendors available.</span></li>
                          <li><em className="icon text-info fs-5 ni ni-check-circle-fill"></em><span>Based on official documentation</span></li>
                          <li><em className="icon text-info fs-5 ni ni-check-circle-fill"></em><span>More vendors to come </span></li> */}
                      </ul>
                  </div>
              </div>
              <div className="col-lg-4 d-flex justify-content-center  align-items-center">
                  <div className={`rounded-5 bg-opacity-50`}>
                      <div className="block-gfx d-flex justify-content-center">
                          <img style={{maxWidth: "350px"}} src={stepImgUrl} alt=""/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}


// Reset store state 
export const RESET_STATE = "RESET_STATE";

// Authentication Actions 
export const SIGNUP = "SIGNUP";
export const SIGNIN = "SIGNIN";

// LoggingOut Action
export const LOGOUT = "LOGOUT";

// Action to fetch loggedin user 
export const FETCH_USER_ME = "FETCH_USER_ME";

// Licences Actions 
export const ADD_LICENCE = "ADD_LICENCE";
export const FETCH_LICENCES = "FETCH_LICENCES";
export const ASSIGN_LICENCE = "ASSIGN_LICENCE";
export const REMOVE_ASSIGNMENT = "REMOVE_ASSIGNMENT";
export const DELETE_LICENCE = "DELETE_LICENCE";

// Subscriptions Actions 
export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";




import FORBIDDEN_IMG from '../../assets/images/gfx/error/403.svg'
import { useTranslation } from 'react-i18next';

export default function Forbidden() {
    const { t } = useTranslation();

  return (
      <section className='section' >
        <div className='container'>
            <div className="row g-gs m-0 justify-content-center">
                <div className="col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                    <div className="block-gfx">
                        <img className="w-100 rounded-4" src={FORBIDDEN_IMG} alt="not found 403 image"/>
                    </div>
                    <div className="block-text text-center mt-4">
                        <h1 className="title">{t("forbidden.title")}</h1>
                        <h3 className="title">{t("forbidden.sub-title")}</h3>
                        <p>{t("forbidden.description")}</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
  )
}

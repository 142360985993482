import React, { useEffect, useState } from 'react';
import './AlertBox.css';

function AlertBox({ message, color, visibility }: { message: string, color: string, visibility: boolean }) {
  const [isVisible, setIsVisible] = useState(visibility);

  useEffect(() => {
    setIsVisible(visibility); // Set the initial visibility

    if (visibility) {
      const timer = setTimeout(() => {
        setIsVisible(false); // Hide the alert after 3 seconds
      }, 4000);

      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, [visibility]);

  return (
    <div className={`alert alert-sm alert-${color} ${isVisible ? 'visible' : 'hidden'} text-center`} role="alert">
      {message}
    </div>
  );
}

export default AlertBox;

import * as types from '../actions/actionsTypes';

// Define the shape of the licenses state

const initialState: any[] = [];

const licences = (state: any[] = initialState, action: any) => {
  switch (action.type) {
    
    //LICENCES FETCH
    case types.FETCH_LICENCES:
    {
      // Update the state with the fetched licenses
      return action.payload.licences;
    }

    //LICENCE DELETION
    case types.ADD_LICENCE:
      {
        // Extract the new license from the action payload
        const newLicence = action.payload.licence;
  
        // Check if a license with the same ID already exists
        const existingLicence = state.find(licence => licence.id === newLicence.id);
  
        if (!existingLicence) {
          // If no matching license is found, create a new state array with the added license
          return [...state, newLicence];
        } else {
          // If a matching license is found, you may choose to handle it accordingly
          // For example, return the current state without making changes, or update it
          return state;
        }
      }

    //LICENCE ASSIGNEMENT
    case types.ASSIGN_LICENCE:
    {
      // Extract the new license from the action payload
      const newLicence = action.payload.licence;
      // Find the index of the old license with a matching identifier (assuming an 'id' property here)
      const index = state.findIndex(licence => licence.id === newLicence.id);

      if (index !== -1) {
        // If a matching license is found, create a new state array with the updated license
        const newState = [...state];
        newState[index] = {
          ...state[index], // Copy the old license
          ...newLicence,   // Merge in the new license properties
        };
        return newState;
      } 
    }
    
    //LICENCE ASSIGNMENT REMOVAL
    case types.REMOVE_ASSIGNMENT:
    {
      // Find the index of the old license with a matching identifier (assuming an 'id' property here)
      const index = state.findIndex(licence => licence.id === action.payload.licenceId);

      if (index !== -1) {
        // If a matching license is found, create a new state array with the updated license
        const newState = [...state];
        newState[index] = {
          ...state[index], // Copy the old license
          user:null,
          status:"AVAILABLE"
        };
        return newState;
      } 
    }
    //LICENCE DELETION
    case types.DELETE_LICENCE:
      {
        // Find the index of the license to be deleted based on its ID
        const index = state.findIndex(licence => licence.id === action.payload.licenceId);
      
        if (index !== -1) {
          // If a matching license is found, create a new state array without the deleted license
          const newState = [...state.slice(0, index), ...state.slice(index + 1)];
          return newState;
        }
      }

    default:
      return state;
  }
};

export default licences;






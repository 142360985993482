import { useSelector } from 'react-redux';
import LicencesManager from '../../../components/LicencesManager/LicencesManager';
import { RootState } from '../../../redux';
import Plans from '../../../components/UI/Plans/Plans';
import './Dashboards.css'
import useLoggedinUser from '../../../hooks/useLoggedinUser';

export default function OrgAdminDashboard() {
  const { USER } = useLoggedinUser();

  return (
    USER.hasSubscriptions &&
    USER.hasAssignedLicence &&
    !["CANCELED_BY_ADMIN", "CANCELED_BY_USER", "SUSPENDED"].includes(USER.subscriptions[0].status) ? (
      <LicencesManager />
    ) : (
      <section className='section'>
        <div className='container'>
          <Plans />
        </div>
      </section>
    )
  );
}
export  const  COUNTRIES: { [key: string]: string[] } = {
        en: [
        "Afghanistan", "Åland Islands", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla",
        "Antarctica", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan",
        "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan",
        "Bolivia (Plurinational State of)", "Bonaire, Sint Eustatius, and Saba", "Bosnia and Herzegovina", "Botswana",
        "Bouvet Island", "Brazil", "British Indian Ocean Territory", "Brunei Darussalam", "Bulgaria", "Burkina Faso",
        "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Cayman Islands", "Central African Republic", "Chad",
        "Chile", "China", "Christmas Island", "Cocos (Keeling) Islands", "Colombia", "Comoros", "Congo",
        "Congo (Democratic Republic of the)", "Cook Islands", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba",
        "Curaçao", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador",
        "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Falkland Islands (Malvinas)",
        "Faroe Islands", "Fiji", "Finland", "France", "French Guiana", "French Polynesia", "French Southern Territories",
        "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada", "Guadeloupe",
        "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Heard Island and McDonald Islands",
        "Holy See", "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia", "Iran (Islamic Republic of)", "Iraq",
        "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kiribati",
        "Korea (Democratic People's Republic of)", "Korea (Republic of)", "Kuwait", "Kyrgyzstan", "Lao People's Democratic Republic",
        "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macao", "Madagascar",
        "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", "Mauritius",
        "Mayotte", "Mexico", "Micronesia (Federated States of)", "Moldova (Republic of)", "Monaco", "Mongolia", "Montenegro",
        "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Caledonia", "New Zealand",
        "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "North Macedonia", "Northern Mariana Islands", "Norway", "Oman",
        "Pakistan", "Palau", "Palestine, State of", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Pitcairn",
        "Poland", "Portugal", "Puerto Rico", "Qatar", "Réunion", "Romania", "Russian Federation", "Rwanda", "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha", "Saint Kitts and Nevis", "Saint Lucia", "Saint Martin (French part)",
        "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia",
        "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Sint Maarten (Dutch part)", "Slovakia", "Slovenia",
        "Solomon Islands", "Somalia", "South Africa", "South Georgia and the South Sandwich Islands", "South Sudan", "Spain",
        "Sri Lanka", "Sudan", "Suriname", "Svalbard and Jan Mayen", "Sweden", "Switzerland", "Syrian Arab Republic",
        "Taiwan, Province of China", "Tajikistan", "Tanzania, United Republic of", "Thailand", "Timor-Leste", "Togo", "Tokelau",
        "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands", "Tuvalu", "Uganda",
        "Ukraine", "United Arab Emirates", "United Kingdom of Great Britain and Northern Ireland", "United States of America",
        "United States Minor Outlying Islands", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela (Bolivarian Republic of)", "Viet Nam",
        "Virgin Islands (British)", "Virgin Islands (U.S.)", "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"
    ],
    fr: [
        "Afghanistan", "Îles Åland", "Albanie", "Algérie", "Samoa américaines", "Andorre", "Angola", "Anguilla",
        "Antarctique", "Antigua-et-Barbuda", "Argentine", "Arménie", "Aruba", "Australie", "Autriche", "Azerbaïdjan",
        "Bahamas", "Bahreïn", "Bangladesh", "Barbade", "Bélarus", "Belgique", "Belize", "Bénin", "Bermudes", "Bhoutan",
        "Bolivie (État plurinational de)", "Bonaire, Saint-Eustache et Saba", "Bosnie-Herzégovine", "Botswana",
        "Île Bouvet", "Brésil", "Territoire britannique de l'océan Indien", "Brunéi Darussalam", "Bulgarie", "Burkina Faso",
        "Burundi", "Cap-Vert", "Cambodge", "Cameroun", "Canada", "Îles Caïmans", "République centrafricaine", "Tchad",
        "Chili", "Chine", "Île Christmas", "Îles Cocos (Keeling)", "Colombie", "Comores", "Congo",
        "Congo (République démocratique du)", "Îles Cook", "Costa Rica", "Côte d'Ivoire", "Croatie", "Cuba",
        "Curaçao", "Chypre", "République tchèque", "Danemark", "Djibouti", "Dominique", "République dominicaine", "Équateur",
        "Égypte", "El Salvador", "Guinée équatoriale", "Érythrée", "Estonie", "Eswatini", "Éthiopie", "Îles Falkland (Malvinas)",
        "Îles Féroé", "Fidji", "Finlande", "France", "Guyane française", "Polynésie française", "Terres australes françaises",
        "Gabon", "Gambie", "Géorgie", "Allemagne", "Ghana", "Gibraltar", "Grèce", "Groenland", "Grenade", "Guadeloupe",
        "Guam", "Guatemala", "Guernesey", "Guinée", "Guinée-Bissau", "Guyana", "Haïti", "Îles Heard-et-MacDonald",
        "Saint-Siège", "Honduras", "Hong Kong", "Hongrie", "Islande", "Inde", "Indonésie", "Iran (République islamique d')", "Irak",
        "Irlande", "Île de Man", "Israël", "Italie", "Jamaïque", "Japon", "Jersey", "Jordanie", "Kazakhstan", "Kenya", "Kiribati",
        "Corée (République populaire démocratique de)", "Corée (République de)", "Koweït", "Kirghizistan", "République démocratique populaire lao",
        "Lettonie", "Liban", "Lesotho", "Libéria", "Libye", "Liechtenstein", "Lituanie", "Luxembourg", "Macao", "Madagascar",
        "Malawi", "Malaisie", "Maldives", "Mali", "Malte", "Îles Marshall", "Martinique", "Mauritanie", "Maurice", "Mayotte",
        "Mexique", "Micronésie (États fédérés de)", "Moldova (République de)", "Monaco", "Mongolie", "Monténégro",
        "Montserrat", "Maroc", "Mozambique", "Myanmar", "Namibie", "Nauru", "Népal", "Pays-Bas", "Nouvelle-Calédonie",
        "Nouvelle-Zélande", "Nicaragua", "Niger", "Nigeria", "Niue", "Île Norfolk", "Macédoine du Nord", "Îles Mariannes du Nord",
        "Norvège", "Oman", "Pakistan", "Palaos", "Palestine, État de", "Panama", "Papouasie-Nouvelle-Guinée", "Paraguay", "Pérou",
        "Philippines", "Pitcairn", "Pologne", "Portugal", "Porto Rico", "Qatar", "Réunion", "Roumanie", "Fédération de Russie",
        "Rwanda", "Saint-Barthélemy", "Sainte-Hélène, Ascension et Tristan da Cunha", "Saint-Christophe-et-Niévès",
        "Sainte-Lucie", "Saint-Martin (partie française)", "Saint-Pierre-et-Miquelon", "Saint-Vincent-et-les Grenadines", "Samoa",
        "Saint-Marin", "Sao Tomé-et-Principe", "Arabie saoudite", "Sénégal", "Serbie", "Seychelles", "Sierra Leone", "Singapour",
        "Saint-Martin (partie néerlandaise)", "Slovaquie", "Slovénie", "Îles Salomon", "Somalie", "Afrique du Sud", "Géorgie du Sud et les îles Sandwich du Sud",
        "Soudan du Sud", "Espagne", "Sri Lanka", "Soudan", "Suriname", "Svalbard et l'île Jan Mayen", "Suède", "Suisse",
        "République arabe syrienne", "Taïwan, Province de Chine", "Tadjikistan", "Tanzanie, République-Unie de", "Thaïlande", "Timor-Leste",
        "Togo", "Tokelau", "Tonga", "Trinité-et-Tobago", "Tunisie", "Turquie", "Turkménistan", "Îles Turques-et-Caïques", "Tuvalu", "Ouganda",
        "Ukraine", "Émirats arabes unis", "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord", "États-Unis d'Amérique",
        "Îles mineures éloignées des États-Unis", "Uruguay", "Ouzbékistan", "Vanuatu", "Venezuela (République bolivarienne du)", "Viet Nam",
        "Îles Vierges britanniques", "Îles Vierges des États-Unis", "Wallis-et-Futuna", "Sahara occidental", "Yémen", "Zambie", "Zimbabwe"
    ]
} 
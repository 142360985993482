import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { Link } from 'react-router-dom';
import { fetchUserStripePortalService } from '../../../services/users.service';
import { useTranslation } from 'react-i18next';

export default function StripePortalLink() {
  const { t } = useTranslation();
    const [ stripePortalUrl, setStripePortalUrl ] = useState<string>('');
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
    const { access_token } = useSelector((state: RootState) => state.auth.userData);
    const { me } = useSelector((state: RootState) => state.users);

    useEffect(()=>{
    if (isLoggedIn){
      fetchUserStripePortalService(access_token)
      .then (response=>{
        setStripePortalUrl(response.url);
        }
      )
      .catch ((error)=>{
        throw error;
        }
      )
    }
    },[])
    
  return (
    ['ORGANISATION_ADMIN','MEMBER'].includes(me.role) &&
    <Link to={stripePortalUrl} className="nk-menu-link w-100">{t('nav.billingstripe')}</Link>
  )
}

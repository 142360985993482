export default  class User {
    
    id: number;
    first_name: string;
    last_name:string;
    email:string;
    gender:string;
    phone_number:string;
    billing_address:string;
    billing_address_complement:string;
    postal_code:string;
    city:string;
    country:string;
    is_particular:boolean;
    status_sub: string;
    organisation:any;
    vat_number:string;
    role: string;
    subscriptions:any;
    licenses:any;
    // Add more attributes as needed
  
    constructor(userAttributes: {
        id: number;
        first_name:string;
        last_name:string;
        email:string;
        gender:string;
        phone_number:string;
        billing_address:string;
        billing_address_complement:string;
        postal_code:string;
        city:string;
        country:string,
        is_particular:boolean;
        status_sub: string;
        organisation:any;
        vat_number:string;
        role: string;
        subscriptions:any;
        licenses:any;
      // Add more attributes as needed
    }) {
        this.id=userAttributes.id;
        this.first_name=userAttributes.first_name;
        this.last_name=userAttributes.last_name;
        this.email=userAttributes.email;
        this.gender=userAttributes.gender;
        this.phone_number=userAttributes.phone_number;
        this.billing_address=userAttributes.billing_address;
        this.billing_address_complement=userAttributes.billing_address_complement;
        this.postal_code=userAttributes.postal_code;
        this.city=userAttributes.city;
        this.country=userAttributes.country;
        this.is_particular=userAttributes.is_particular;
        this.status_sub =userAttributes.status_sub;
        this.organisation=userAttributes.organisation;
        this.vat_number=userAttributes.vat_number;
        this.role=userAttributes.role;
        this.subscriptions=userAttributes.subscriptions;
        this.licenses=userAttributes.licenses;
      // Initialize more attributes as needed
    }
  
    // You can also define methods or computed properties for the User class
    
    // USER getters  

    // this getter return the fullname of the loggedin user
    public get fullName (): string {
        const fullname = this.first_name+ ' ' + this.last_name; 
        return fullname
        .split(' ') // Split the input string into words based on spaces
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Join the words back together with spaces

    }

    // this getter is to check if the loggedin user is a super admin 
    public get isSuperAdmin(): boolean {
        return this.role === 'SUPER_ADMIN';
    }

    // this getter is to check if the logged in user is an organization admin 
    public get isOrgAdmin(): boolean {
        return this.role === 'ORGANISATION_ADMIN';
    }

    // this getter is to check if the loggedin user is a particular
    public get isParticular(): boolean {
        return this.role === 'MEMBER';
    }

    // this getter is to check if the logged in usr is an organization member 
    public get isOrgMember(): boolean {
        return this.role === 'ORGANISATION_MEMBER';
    }

    // this getter is to check if the loggedin user has an assigned licence
    public get hasAssignedLicence (): boolean {
        return  (this.licenses && this.licenses.length>0);

    }

    // this getter is to check if the loggedin user has an active subscription 
    public get hasSubscriptions (): boolean {
        return  (this.subscriptions && this.subscriptions.length>0);

    }
    
    // this getter return boolean if the loggedin user has the access to the chatbot  
    public get isAuthorized (): boolean {
        return (
            (this.hasSubscriptions && (
                this.subscriptions[0].status=="ACTIVE" || 
                (this.subscriptions[0].status=="PAYMENT_INTENT" && this.subscriptions[0].free_period==true)
                )
            ) || (this.hasAssignedLicence && ["ACTIVE", "PAYMENT_INTENT"].includes (this.status_sub))
        );
    }


  }
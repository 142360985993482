import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

interface ModalProps {
    title:string;
    modalContent:React.ReactNode;
    show: boolean;
    isDisabled?:boolean;
    onHide: () => void;
    onConfirm:()=>void;
  }
  
export const ModalBs : React.FC<ModalProps> = ({ show, title,modalContent,isDisabled, onHide, onConfirm }) => {
  const {t} = useTranslation();
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton  className='d-flex justify-content-center'>
          <Modal.Title className='d-flex justify-content-center'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>{modalContent}</Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button variant="success " onClick={onConfirm} disabled={isDisabled}>
            {t("buttons.confirm")} 
          </Button>
          <Button variant="danger " onClick={onHide}>
            {t("buttons.cancel")} 
          </Button>
        </Modal.Footer>
      </Modal>
    )}
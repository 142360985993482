import { useState } from 'react';

export const useAsyncLoader = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>(null);

  const handler = async (callBack: () => Promise<void>) => {
    try {
      setIsLoading(true);
      await callBack();
    } catch (error: any) {
      setErrors(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    errors,
    handler,
  };
}

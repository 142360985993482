import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'; // Import useHistory from react-router-dom
import Section from '../../components/UI/Section/Section'
import {useDispatch} from 'react-redux';
import {loginService} from '../../services/auth.service';
import { AppDispatch } from '../../redux';
import AlertBox from '../../components/UI/AlertBox/AlertBox';
import LOGO_IMG from '../../assets/images/logo-no-text.png'
import { useTranslation } from 'react-i18next';
import { useAsyncLoader } from '../../hooks/useAsyncLoader';
import Spinner from '../../components/UI/Spinner/Spinner';

export default function Signin() {
    const { t } = useTranslation(); //  access to translation functions in the application.
    const {handler,errors, isLoading} = useAsyncLoader();
    const navigate = useNavigate(); // Initialize navigate from react-router-dom
    const [username, setUsername] = useState('');   // Initialize username
    const [password, setPassword] = useState('');   // Initialize password
    const [Errors, setErrors] = useState('');   // Initialize Errors
    const [isVisible, setIsVisible] = useState(false);  // Initialize the AlertBox visibility 
    const dispatch: AppDispatch = useDispatch();    // Initialize password
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Login Function
    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();
        handler(()=>dispatch(loginService({ username, password })))
          .then(() => {
            // If the dispatch is successful, navigate to '/Dashboard'
            navigate('/dashboard');
          })
          .catch((error) => {
            setIsVisible(true);
            setErrors(error);
          });
      };

      // Timer to controle the visibility of the AlertBox
      useEffect(() => {
        const timer = setTimeout(() => {
        setIsVisible(false);
        }, 4000); // Hide the error message after 5 seconds
      
        return () => {
          clearTimeout(timer);
        };
      }, [isVisible]);

  return (
    <>
        {isLoading && <Spinner/>}
        <section className='section'>
            <div className="nk-shape bg-shape-blur-a  position-fixed start-50 top-50 translate-middle"></div>
            <div className="container">
                <div className="section-head pb-5">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-11 col-xl-10 col-xxl-9">
                            <Link to={'/'} className='allianceLogoLink' >
                                <img className="h-5rem mb-3 " src={LOGO_IMG}  alt=""/>
                            </Link>   
                            <h4 className="title">Alliance-Com </h4>
                            <h3 className="title">{t("signin.connect")}</h3>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row g-gs justify-content-center">
                        <div className="col-md-8 col-lg-7 col-xl-5">
                            <div className="card border-0 shadow-sm rounded-4 mb-2">
                                <div className="card-body">
                                    <form onSubmit={handleLogin} >
                                        <div className="row g-4">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="email">{t("signin.email")}</label>
                                                    <div className="form-control-wrap">
                                                        <input 
                                                        type="email" 
                                                        name="email" 
                                                        id="email" 
                                                        className="form-control form-control-lg" 
                                                        placeholder={t("signin.email-placeholder")}
                                                        required
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="toggle-password">{t("signin.pwd")}</label>
                                                    <div className="form-control-wrap">
                                                        <Link to="#"  onClick={togglePasswordVisibility} className={`form-control-icon end password-toggle ${showPassword ? ' is-shown' : ''} `} title="Toggle show/hide password">
                                                            <em className="on icon ni ni-eye text-base"></em>
                                                            <em className="off icon ni ni-eye-off text-base"></em>
                                                        </Link>
                                                        <input 
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="password" 
                                                        id="toggle-password" 
                                                        className="form-control form-control-lg" 
                                                        placeholder={t("signin.pwd-placeholder")}
                                                        required
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-12">
                                                <div className="d-flex flex-wrap justify-content-between has-gap g-3">
                                                    {/* <div className="form-group">
                                                        <div className="form-check form-check-sm">
                                                            <input className="form-check-input" type="checkbox" value="" id="rememberMe"/>
                                                            <label className="form-check-label" htmlFor="rememberMe"> Remember Me </label>
                                                        </div>
                                                    </div> */}
                                                    <Link to="/resetpassword" className="small link link-primary ">{t("signin.pwd-forgotten")}</Link>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <button className="btn btn-primary btn-block mb-4" type="submit" id="submit-btn">{t("signin.btn")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span className='me-2'>{t("signin.no-account")}</span>
                                        <Link to="/signup" className=" link link-primary ">{t("signin.link")}</Link>
                                    </div>
                                </div>
                            </div>
                            <AlertBox color='danger' message={Errors} visibility={isVisible} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

import axios from "axios";
import { AppDispatch } from "../redux";
import { addLicence, assignLicence, deleteLicence, fetchLicences, removeLicenceAssignment } from "../redux/actions";
import { URL } from "../api/constants";
import { getConfig } from "./config.service";

//Fetch Lincences 
export const fetchLicencesService =  (licences:any)=> async (dispatch: AppDispatch)=> {

  return dispatch(fetchLicences(licences));

}

// Lincence Addition  
export const addLicenceService =  (subscription:any, accessToken: string )=> async (dispatch: AppDispatch)=> {
  
  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;

  const url = `${API_HOST_URL+URL.API_LICENCE}?subscription_id=${subscription.id}`;

  await axios.post(
    url,
    {},
    { headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }},
    )
    .then((response)=>{
      //adding the subscription product infos to the new licence  
      const newLicence = {...response.data, product:{...subscription.product}} 
      dispatch(addLicence(newLicence)) ;
      return (response.data);
    }) 
    .catch ((error:any)=> {
      if (error.response) {
        // Server returned an error response
        throw error.response.data.detail;
      } else {
        // Network error or other issues
        throw error.message;
      }
    });
}

// Lincence Assignment  
export const assignLicenceService =  (licenceId:undefined,userEmail:string, accessToken: string )=> async (dispatch: AppDispatch)=> {

  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;

  const url = `${API_HOST_URL+URL.API_LICENCE_ASSIGNMENT}/${licenceId}`;

  await axios.patch(url, null, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    params:{
      email:userEmail
    }
  })
  .then((response)=>{
    dispatch(assignLicence(response.data)) ;
    return response.data;
  })
  .catch ((error:any)=> {
    if (error.response) {
      // Server returned an error response
      throw error.response.data.detail;
    } else {
      // Network error or other issues
      throw error.message;
    }
  });
}

// Lincence Assignment Removal   
export const removeLicenceAssignementService =  (licenceId:number, accessToken: string )=> async (dispatch: AppDispatch)=> {

  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;

  const url = `${API_HOST_URL+URL.API_LICENCE_ASSIGNMENT}/${licenceId}`;
    await axios.delete(url, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response)=>{
      dispatch(removeLicenceAssignment(licenceId))
      return (response.data);
    }).catch((error)=>{
      throw error;
    })
}

// Lincence Assignment Removal   
export const deleteLicenceService =  (licenceId:number, accessToken: string )=> async (dispatch: AppDispatch)=> {
  
  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;

  const url = `${API_HOST_URL+URL.API_LICENCE}?licence_id=${licenceId}`;
  
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.delete(url,
    { headers }
  ).then((response)=>{
    dispatch(deleteLicence(licenceId));
    return (response.data);
  }).catch((error)=>{
    throw error;
  });
}

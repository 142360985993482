import axios from "axios";
import { URL } from "../api/constants";
import { AppDispatch } from "../redux";
import { fetchUserMe } from "../redux/actions";
import { getConfig } from "./config.service";

// Fetch Users me Service 
export const fetchUsersMeService = (accessToken:string) => async (dispatch: AppDispatch) => {

  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;
  
  const headers = {
    accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(API_HOST_URL+URL.API_USERS_ME,{ headers });
    return dispatch(fetchUserMe(response.data));

  } catch (error:any) {
      throw error.response.data;
    }
};


// Fetch User 'Stripe  Portal 
export const fetchUserStripePortalService = async(accessToken:string) =>  {

  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;
  
  const headers = {
    accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(API_HOST_URL+URL.API_USER_STRIPE_PORTAL,{ headers });
    return response.data;

  } catch (error:any) {
      throw error.response.data;
    }
};

// ChangePassword Service
export const changePasswordService = async(accessToken:string, current_password:string, new_password:string) =>  {

  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;
  
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const data = {
    current_password: current_password,
    new_password: new_password,
  };

  try {
    await axios.patch(API_HOST_URL+URL.API_USER_CHANGE_PWD, data, { headers });
  } catch (error:any) {
    throw  error.response;
    }
};

// Get Password reset link Service 
export const getPasswordResetLinkService = async(email:string) =>  {
  let config = await getConfig();
  let API_HOST_URL = config.API_HOST_URL
  try {
  const response = await axios.post(
    API_HOST_URL+URL.API_PWD_GET_RESET_LINK, 
    { email }, 
    { headers: {
      'Content-Type': 'application/json'
    },
  });
  return response.data
} catch(error:any) {
    throw  error.response;
    }
}

// Get Password reset link Service 
export const resetPasswordService = async(password_one: string, password_two: string, token:string|null) =>  {
  let config = await getConfig();
  let API_HOST_URL = config.API_HOST_URL
  try {
  const response = await axios.post(
    API_HOST_URL+URL.API_PWD_RESET, 
    { password_one, password_two, token }, 
    { headers: {
      'Content-Type': 'application/json'
    },
  });
  return response.data
} catch(error:any) {
      throw error ;
    }
}

import LOGO_IMG from '../../../assets/images/alliancecom.svg';
import LOGO_DARK_IMG from '../../../assets/images/logo-dark2x.png';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../../redux';
import { logoutService } from '../../../services/auth.service';
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import StripePortalLink from '../StripePortalLink/StripePortalLink';
import { useChat } from '../../../contexts/ChatProvider';
import LanguageSlelector from '../LanguageSelector/LanguageSlelector';
import { useTranslation } from 'react-i18next';
import './Nav.css';
import useLoggedinUser from '../../../hooks/useLoggedinUser';

export default function Nav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { USER } = useLoggedinUser();
  const dispatch: AppDispatch = useDispatch();
  const { activeChat, setActiveChat } = useChat();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const user = useSelector((state: RootState) => state.users.me);
  const userData = useSelector((state: RootState) => state.auth.userData);
  const [isFixed, setIsFixed] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  useEffect(()=>{
      // reseting the active chat when rendering any page different than '/chat'
      if (location.pathname!== '/chat')
      setActiveChat('');
  }),[]

  const handleChatbotSelect = (chatbot: string) => {
      window.localStorage.setItem('activeChat', chatbot);
    setActiveChat(chatbot);
  }
  // Logginout Function
  const handleLogout = () => {
    dispatch(logoutService());
    navigate('/');
  };

  useEffect(()=>{
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      // Check if the clicked element is outside the mobile menu container
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(e.target as Node)) {
        setMobileMenuVisibility(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isMobileMenuVisible]);

  const toggleMobileMenu = () => {
    setMobileMenuVisibility(prev =>!prev);
  };
  

  return (
    <div className={`nk-header-main nk-menu-main  will-shrink on-dark ignore-mask ${isFixed ? 'has-fixed bg-dark' : ''}`} >
      <div className="container">
          <div className="nk-header-wrap gap-6">
              <div className="nk-header-logo">
                  <a href={isLoggedIn ?"dashboard" : "/"}>
                      <div className="logo-wrap">
                          <img className="logo-img logo-light h-2rem" src={LOGO_IMG} alt=""/>
                          <img className="logo-img logo-dark" src={LOGO_DARK_IMG} alt=""/>
                      </div>
                  </a>
              </div>
              <div className="nk-header-toggle">
                  <button className="btn btn-dark btn-icon header-menu-toggle" onClick={toggleMobileMenu} >
                      <em className="icon ni ni-menu"></em>
                  </button>
              </div>
              {
                isLoggedIn ?
                <nav className={`nk-header-menu nk-menu  nk-menu-mobile ${isMobileMenuVisible ? 'menu-mobile header-menu-active' : ''}`}>
                  { isMobileMenuVisible &&
                    <div className='d-flex  justify-content-between w-100 pe-2 ps-4'>
                      <div className="nk-header-logo ">
                          <a href={isLoggedIn ?"dashboard" : "/"}>
                              <div className="logo-wrap">
                                  <img className="logo-img logo-light h-2rem" src={LOGO_IMG} alt=""/>
                                  <img className="logo-img logo-dark" src={LOGO_DARK_IMG} alt=""/>
                              </div>
                          </a>
                      </div>
                      <button className="btn  btn-icon header-menu-toggle close-btn" onClick={toggleMobileMenu}>
                        <em className="icon ni ni-cross"></em>
                      </button>
                    </div>
                  }
 
                { USER.isAuthorized &&
                  <ul className="nk-menu-list me-auto ">
                      <li className={`nk-menu-item ${activeChat === 'ALE' ? 'active current-page' : ''}`}>
                        <Link to="/chat" onClick={()=>handleChatbotSelect('ALE')}  className="nk-menu-link">
                          <span className="nk-menu-text">Alcatel-Lucent Enterprise</span>
                        </Link>
                      </li>

                        <li className={`nk-menu-item ${activeChat === 'HIKVISION' ? 'active current-page' : ''}`}>
                          <Link to="/chat" onClick={()=>handleChatbotSelect('HIKVISION')}  className="nk-menu-link">
                            <span className="nk-menu-text">HIKVISION</span>
                          </Link>
                      </li>

                      <li className={`nk-menu-item ${activeChat === 'MITEL' ? 'active current-page' : ''}`}>
                        <Link to="/chat" onClick={()=>handleChatbotSelect('MITEL')} className="nk-menu-link">
                          <span className="nk-menu-text">MITEL</span>
                        </Link>
                      </li>


                        <li className={`nk-menu-item ${activeChat === 'ZYXEL' ? 'active current-page' : ''}`}>
                            <Link to="/chat" onClick={()=>handleChatbotSelect('ZYXEL')}  className="nk-menu-link">
                              <span className="nk-menu-text">ZYXEL</span>
                            </Link>
                        </li>

                  </ul>
                  }
                  <ul className={`nk-menu-buttons  ${USER.isAuthorized ? '' : 'ms-auto'}`}>
                    <li><LanguageSlelector/></li>
                    <li>
                      <div className="nk-menu-item has-dropdown ">
                      <Link to="/dashboard" className=" nk-menu-link nk-menu-toggle">
                        <span className="nk-menu-text">{`${t('nav.greeting')} ${userData.user.first_name}`}</span>
                      </Link>
                      <div className="nk-menu-dropdown bg-dark rounded-3">
                        <div className="nk-menu">
                          <ul className="nk-menu-sub">
                            <li className="nk-menu-item">
                              <Link to="/profile" className="nk-menu-link w-100" >{t('nav.profile')}</Link>
                            </li>
                            <li className="nk-menu-item w-100">
                              <StripePortalLink/>
                            </li>
                            <li className="nk-menu-item  w-100"><hr className="dropdown-divider"/></li>
                            <li className="nk-menu-item">
                              <Link to="/" className="nk-menu-link w-100" onClick={handleLogout}>
                                {t('nav.logout')} 
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      </div>
                    </li>
                  </ul>
                </nav> 
                : 
                <nav className={`nk-header-menu nk-menu align-items-start  nk-menu-mobile ${isMobileMenuVisible ? 'menu-mobile header-menu-active ' : ''}`}>
                  { isMobileMenuVisible &&
                    <div className='d-flex  justify-content-between w-100 pe-2 ps-4'>
                      <div className="nk-header-logo ">
                          <a href={isLoggedIn ?"dashboard" : "/"}>
                              <div className="logo-wrap">
                                  <img className="logo-img logo-light h-2rem" src={LOGO_IMG} alt=""/>
                                  <img className="logo-img logo-dark" src={LOGO_DARK_IMG} alt=""/>
                              </div>
                          </a>
                      </div>
                      <button className="btn  btn-icon header-menu-toggle close-btn" onClick={toggleMobileMenu}>
                        <em className="icon ni ni-cross"></em>
                      </button>
                    </div>
                  }
                  <ul className="nk-menu-list me-auto">
                    <li className="nk-menu-item  current-page">
                      <a href="#service" className="nk-menu-link">
                        <span className="nk-menu-text">{t('nav.service')}</span>
                      </a>
                    </li>
                    <li className="nk-menu-item">
                      <a href="#howto" className="nk-menu-link">
                        <span className="nk-menu-text">{t('nav.howto')}</span>
                      </a>
                    </li>
                    <li className="nk-menu-item">
                        <a href="#pricing" className="nk-menu-link">
                          <span className="nk-menu-text">{t('nav.price')}</span>
                        </a>
                    </li>
                    <li className="nk-menu-item">
                      <a href="#faq" className="nk-menu-link">
                        <span className="nk-menu-text">{t('nav.faq')}</span>
                      </a>
                    </li>
                  </ul>
                  <ul className={`nk-menu-buttons d-sm-flex nk-menu-mobile ${isMobileMenuVisible ? 'border d-flex flex-column-reverse gap-2 ' : ''}`}>
                      <li>
                        <LanguageSlelector/>
                      </li>
                      <li><Link to="/signup" className=" link link-light  ">{t('nav.signup')}</Link></li>
                      <li><Link to="/signin" className=" btn btn-outline-primary rounded-pill">{t('nav.signin')}</Link></li>
                  </ul>
                </nav> 
              }
          </div>
      </div>
    </div>
  )
}

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import EN_IMG from '../../../assets/images/languages/en.png';
import FR_IMG from '../../../assets/images/languages/fr.png';
import './LanguageSelector.css'

export default function LanguageSlelector() {
    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'fr');

    const { i18n } = useTranslation();

      // Update local storage when the language changes
    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
      setLanguage(lng);
      localStorage.setItem('selectedLanguage', lng);

    };

    // Use useEffect to initialize the language when the component mounts
    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
        setLanguage(storedLanguage);
        }
    }, []);



  return (
    <div className="nk-menu-item has-dropdown ">
        <Link to="#" className=" nk-menu-link nk-menu-toggle d-flex justify-content-center align-items-center">
        {   language == 'fr'
            ? <img  src={FR_IMG}  alt='french flag' width={20}/>  
            : <img  src={EN_IMG}  alt='english flag' width={20}/> 
        }
        </Link>
        <div className="nk-menu-dropdown language-selector bg-dark rounded-3">
            <ul className="nk-menu-sub">
                <li className="nk-menu-item">
                    <Link to="#" className="nk-menu-link" onClick={()=>changeLanguage('fr')}>
                        FR 
                    </Link>
                </li>
                <li className="nk-menu-item">
                <Link to="#" className="nk-menu-link w-100" onClick={()=>changeLanguage('en')}>
                    EN
                </Link>
                </li>
            </ul>
        </div>
    </div>
    
    )
}

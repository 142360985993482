import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import './SuccessMsg.css'

interface SuccessMsgProps {
    title: string;
    text :string;
    route:string;
}

export default function SuccessMsg({title,text,route}:SuccessMsgProps) {
    const {t}= useTranslation();

  return (
    <section className='section h-100'>
      <div className="main-container">
        <div className="check-container">
          <div className="check-background">
            <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className="check-shadow"></div>
        </div>
        <div className="block-text text-center mt-4">
          <h3 className="title">{title}</h3>
          <p>{text}</p>
          <Link 
            className="btn btn-lg btn-success rounded-pill mb-3" 
            to={route}>
            <span>{t("buttons.continue")}</span>
          </Link>
        </div>
      </div>
    </section>




  )
}

import { useSelector } from "react-redux";
import { RootState } from "../redux";
import User from "../models/User";

/*  We have created this LoggedinUser hook in order to simplify the access to the store data and certain composite controls 
    we may need all alongso we created a User Class with getters that simplfy this controls controls
    and instantiate a user with related store data  */ 
export default function useLoggedinUser() {
  const user = useSelector((state: RootState) => state.users.me);
  const userData = {
      id: user.id,
      first_name:user.first_name ,
      last_name:user.last_name,
      email:user.email,
      gender:user.gender,
      phone_number:user.phone_number,
      billing_address: user.billing_address,
      billing_address_complement:user.billing_address_complement,
      postal_code:user.postal_code,
      city:user.city,
      country:user.country,
      is_particular:user.is_particular,
      status_sub: user.status_sub,
      organisation:user.organisation,
      vat_number:user.vat_number,
      role: user.role,
      subscriptions:user.subscriptions,
      licenses:user.licenses,
      // subscriptions:[],
      // licenses:[],
    };
    
  const USER = new User(userData);

  return ( { USER } )
}

import React, {useState} from 'react'
import {Link} from 'react-router-dom'; // Import useHistory from react-router-dom
import AlertBox from '../../components/UI/AlertBox/AlertBox';
import LOGO_IMG from '../../assets/images/logo-no-text.png'
import { useTranslation } from 'react-i18next';
import { useAsyncLoader } from '../../hooks/useAsyncLoader';
import Spinner from '../../components/UI/Spinner/Spinner';
import { getPasswordResetLinkService } from '../../services/users.service';
import { isEmailValid } from '../../utils';

export default function ResetPassword() {
    const { t } = useTranslation(); //  access to translation functions in the application.
    const { handler, isLoading, errors } = useAsyncLoader();
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [isSuccess , setIsSuccess] = useState(false);
    const [isVisible , setIsVisible] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(e.target.value);
};
    const handleResetPassword = (email :string) => {
        handler (()=>getPasswordResetLinkService(email)
        .then(response=> {
            setEmail('')
            setIsVisible(true);
            setIsSuccess(true);
            setMessage(response.message)
        })
        .catch(error => {
            setIsVisible(true);
            setIsSuccess(false);
            setMessage(error.detail);
            
            const errorMessages: { [key: number]: string } = {
                400: t("responses.pwd-reset.400"),
                401: t("responses.pwd-update.401"),
                500: t("responses.500")
              } 
              setIsVisible(true)
              setMessage(errorMessages[error.status])
        })
        );
    }

    // onSubmit={()=>handleResetPassword(email)}
  return (
        isLoading 
        ? <Spinner/>
        : <section className='section'>
            <div className="nk-shape bg-shape-blur-a  position-fixed start-50 top-50 translate-middle"></div>
            <div className="container">
                <div className="section-head pb-5">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-11 col-xl-10 col-xxl-9">
                            <Link to={'/'} className='allianceLogoLink' >
                                <img className="h-5rem mb-3 " src={LOGO_IMG}  alt=""/>
                            </Link>   
                            <h4 className="title">AllianceCom </h4>
                            <h3 className="title">{t("password-reset.title")}</h3>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="row g-gs justify-content-center">
                        <div className="col-md-8 col-lg-7 col-xl-5">
                            <div className="card border-0 shadow-sm rounded-4 mb-2">
                                <div className="card-body">
                                    <p>{t("password-reset.text")}</p>
                                    <form action="#" onSubmit={()=>handleResetPassword(email)}> 
                                        <div className="row g-4">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="emailorusername">{t("password-reset.email")}</label>
                                                    <div className="form-control-wrap">
                                                        <input type="email" value={email} name="emailorusername" id="emailorusername" className="form-control form-control-lg" placeholder={t("password-reset.email-placeholder")} onChange={handleChange}required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block" type="submit" id="submit-btn" disabled={!email || !isEmailValid(email)}>{t("buttons.reset")}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <AlertBox color={isSuccess ?'success':'danger'} message={message} visibility={isVisible} />
                        </div>
                    </div> 
                </div>
            </div>
          </section>
  )
}

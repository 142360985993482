import React from 'react'

/**
 * Section Component
 * @param {string} id - Section id -optional- anchor for scrolling .
 * @param {string} title - Section Title.
 * @param {string} lead - Section Descirption.
 * @param {string} badge - Section Badge.
 * @param {string} classNames - series of bootstrap classes to apply for the section .
 * @param {React.ReactNode} children - Section Content passed as children .
 */

//  Interface for  Section props
interface SectionProps {
  id?:string;
  title?:string;
  lead?:string;
  badge?:string;
  classNames:string;
  children:React.ReactNode;
}

export default function Section({ id, title, lead, badge, classNames, children } : SectionProps) {

  return (
    <section id={id} className={classNames}>
      <div className="nk-shape bg-shape-blur-a start-50 top-50 translate-middle"></div>
        <div className="container">
          <div className="section-head">
              <div className="row justify-content-center text-center">
                  <div className="col-lg-9 col-xl-8 col-xxl-7 d-flex align-items-center flex-column">
                      { badge && <div className="badge text-bg-primary-soft-outline text-uppercase text-tracking-1 rounded-pill px-3 py-2 mb-3">{badge}</div> }
                      <h2 className="title">{title}</h2>
                      <p className="lead ">{lead}</p>
                  </div>
              </div>
          </div>
          <div className="section-content">
              <div className="row g-gs">
                {/* Section Content here */}
                {children}
              </div>
          </div>
        </div>
    </section>
  )
}

import { useEffect } from 'react';

function InactivityTimer({ onLogout }: { onLogout: () => void }) {
  const inactivityDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
  
  let lastActivityTime = Date.now();
  let timer: number | null = null;

  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = window.setTimeout(onLogout, inactivityDuration);
  };

  const handleUserActivity = () => {
    lastActivityTime = Date.now();
    resetTimer();
  };

  useEffect(() => {
    // Set up event listeners to track user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    // Initial setup
    resetTimer();

    // Check for inactivity and log out if needed
    const checkInactivity = () => {
      if (Date.now() - lastActivityTime >= inactivityDuration) {
        onLogout();
      } else {
        resetTimer(); // Reset the timer if there's still activity
      }
    };

    // Check inactivity periodically
    const interval = setInterval(checkInactivity, 1000);

    // Clean up event listeners and the interval
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      clearInterval(interval);
    };
  }, [onLogout, inactivityDuration]);

  return null;
}

export default InactivityTimer;

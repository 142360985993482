import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {initializeIcons} from "@fluentui/react";
import Home from "./pages/Home/home";
import NotFound from "./pages/NotFound/NotFound";
import Signin from "./pages/Signin/Signin";
import Signup from "./pages/Signup/Signup";
import {Provider} from "react-redux";
import store, {persistor} from "./redux";
import {PersistGate} from "redux-persist/integration/react";
import TermsOfService from "./pages/Terms-of-service/TermsOfService";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import Checkout from "./pages/Checkout/Checkout";
import {getConfig} from "./services/config.service";
import {GlobalContext} from "./contexts/global";
import Layout from "./components/Layout/Layout";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile";
import SignupCompletion from "./pages/SignupCompletion/SignupCompletion";
import Chatbot from "./pages/Chatbot/Chatbot";
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';
import Forbidden from "./pages/Forbidden/Forbidden";
import i18n from 'i18next';
import "./index.css";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import PasswordChange from "./pages/PasswordChange/PasswordChange";
import PrivacyPolicy from "./pages/Privacy-policy/PrivacyPolicy";

// Initialize i18next
i18n
    .use(initReactI18next)
    .init({
        resources: {
        fr: { translation: frTranslation }, // French as default
        en: { translation:  enTranslation }
        },
        lng: 'fr', // Default language is French
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
          },
    });

  initializeIcons();

export default function App() {

    const [config, setConfig] = React.useState({
        STRIPE_PUBLIC_KEY: '',
        OFFERS: [],
    })

    useEffect(()=> {
        getConfig().then((config) => {
            // @ts-ignore
            setConfig(config)
        });
        
    }, [])

    return (
        <GlobalContext.Provider value={{ config }}>
            <BrowserRouter >
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Home />} />
                                <Route 
                                    path="/dashboard"
                                    element={<PrivateRoute element={<Dashboard />} />}
                                />
                                <Route 
                                    path="/checkout"
                                    element={<PrivateRoute element={<Checkout />} />}
                                />
                                <Route 
                                    path="/cart"
                                    element={<PrivateRoute element={<ShoppingCart />} />}
                                />
                                <Route 
                                    path="/profile"
                                    element={<PrivateRoute element={<Profile />} />}
                                />
                                <Route 
                                    path="/chat"
                                    element={<PrivateRoute element={<Chatbot />} />}
                                />
                                <Route 
                                    path="payment-success" 
                                    element={<PrivateRoute element={<PaymentSuccess />} />}
                                />

                                <Route path="terms-of-service" element={<TermsOfService />} />
                                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="signin" element={<Signin />} />
                                <Route path="signup" element={<Signup />} />
                                <Route path="signupcompletion" element={<SignupCompletion />}/>
                                <Route path="resetpassword" element={<ResetPassword />} />
                                <Route path="password-change" element={<PasswordChange />} />
                                <Route path="*" element={<NotFound />} />
                                <Route path="forbidden" element={<Forbidden />} />
                            </Route>

                        </Routes>
                    </PersistGate>
                </Provider>
            </BrowserRouter>
        </GlobalContext.Provider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
            <App />
    </React.StrictMode>
);


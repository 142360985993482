import { useEffect, useState } from 'react';
import useLoggedinUser from '../../hooks/useLoggedinUser';
import WOMAN_IMG from '../../assets/images/avatar/profile/woman-profile.png';
import MAN_IMG from '../../assets/images/avatar/profile/man-profile.png';
import { Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { generateUniqueId } from '../../utils';
import { useAsyncLoader } from '../../hooks/useAsyncLoader';
import { changePasswordService } from '../../services/users.service';
import { RootState } from '../../redux';
import { useSelector } from 'react-redux';
import Spinner from '../UI/Spinner/Spinner';
import AlertBox from '../UI/AlertBox/AlertBox';

const UserProfile = () => {
  const { isLoading, handler } = useAsyncLoader();
  const accessToken = useSelector((state: RootState) => state.auth.userData.access_token); // Token Store Selector 
  const { t , i18n } = useTranslation(); // translation 
  const [key, setKey] = useState('BillingAddress'); // State to set the active Tab
  const [message, setMessage] = useState(''); // State to set success or failure messages 
  const [isVisible, setIsVisible] = useState(false); // State to set the AlertBox visibility  
  const { USER } = useLoggedinUser(); // Hook of the LoggedIn User 
  const [isEditingPwd , setIsEditingPwd]=useState(false); // State to set the state of the PasswordTab (on enditing or not )
  const [showCurrentPassword, setShowCurrentPassword] = useState(false); // State to toggle password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle password visibility
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false); // State to toggle password visibility
  const [billingAddress, setBillingAddress] = useState({ // State to set the values of the Billing address 
    billing_address: '',
    billing_address_complement: '',
    country: '',
    city: '',
    postal_code: '',
  });
  const [passwords, setPasswords] = useState({ // State to set the passwords values in the edit form  
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  });

  const isDisabled =   // variable containing the value of the confirm button status disbled or not 
  [passwords.current_password,passwords.confirm_new_password,passwords.new_password].some((elem)=>elem=='') 
  || (passwords.new_password !== passwords.confirm_new_password );

  // Function to toggle password visibility

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };


  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };



  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
      setPasswords({ ...passwords, [name]: value });
 
  };

  const onActiveEditting=()=>{
    setIsVisible(false);
    setMessage('');
    setIsEditingPwd(true);
  }

  const onCancelEditing=()=>{
    setIsEditingPwd(false);
  }

  const onConfirmPwdUpdate=  ()=>{
    handler(()=>changePasswordService(accessToken, passwords.current_password, passwords.new_password))
    .then(()=>{
      setPasswords({ // State to set the passwords values in the edit form  
        current_password: '',
        new_password: '',
        confirm_new_password: '',
      });
      setIsVisible(true)
      setMessage(t("responses.pwd-update.200"));
      setIsEditingPwd(false);
    })
    .catch((error)=>{
      const errorMessages: { [key: number]: string } = {
        400: t("responses.pwd-update.400"),
        401: t("responses.pwd-update.401"),
        500: t("responses.500")
      } 
      setIsVisible(true)
      setMessage(errorMessages[error.status]
      )
    })
  }

  useEffect(()=>{
    setBillingAddress({
      billing_address: USER.billing_address,
      billing_address_complement: USER.billing_address_complement,
      country: USER.country,
      city: USER.city,
      postal_code: USER.postal_code,
    });
  },[])


  useEffect(()=>{
    setIsVisible(false);
    setMessage('');
  },[i18n.language])

  return (
     isLoading 
     ?  <Spinner/>
     :  <div className='container'>
          <div className=" p-5  bg-white  ">
                <div className="card-body">
                  <div className="e-profile">
                    <div className="row">
                      <div className="col-12 col-sm-auto mb-3">
                        <div className="mx-auto" style={{width: "140px"}}>
                          <div className="d-flex justify-content-center align-items-center rounded" style={{ backgroundColor: "transparent"}}>
                            <span><img src={USER.gender=='Female' ? WOMAN_IMG : MAN_IMG}   /></span>
                          </div>
                        </div>
                      </div>
                      <div className="col d-flex flex-column flex-md-row justify-content-between mb-3">
                        <div className="mx-auto text-start mx-sm-0 ">
                          <h4 className="pt-sm-2 pb-1 mb-0 text-nowrap">{USER.fullName}</h4>
                          <div><small><b>{t("userProfile.email")} :</b></small> <small className="text-muted">{USER.email}</small></div>
                          <div><small><b>{t("userProfile.phone")} :</b></small> <small className="text-muted">{USER.phone_number}</small></div>
                          <div><small><b>{t("userProfile.organization")} :</b></small> <small className="text-muted">{ USER.organisation ? USER.organisation.name :'N/A'}</small></div> 
                          <div><small><b>{t("userProfile.country")} :</b></small> <small className="text-muted">{ USER.country ? USER.country :'N/A'}</small></div> 

                          <div className="mt-2">
        
                          </div>
                        </div>
                        <div className="text-center text-sm-left">
                          <span className="badge text-bg-info-soft">{USER.role}</span>
                        </div>
                      </div>
                    </div>

                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k:any) => setKey(k)} className="mb-3">

                      <Tab eventKey="BillingAddress" title={t("userProfile.tab1.title")}>
                        {/* Billing Address Fields */}
                        <form className='container d-flex flex-column gap-3 mt-6'>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="billing_address">{t("userProfile.tab1.billing-address")}</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="billing_address"
                                  id="billing_address"
                                  value={billingAddress.billing_address}
                                  className="form-control form-control-lg"
                                  placeholder={t("userProfile.tab1.billing-address-placeholder")}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="billing_address_complement">{t("userProfile.tab1.additional-address")}</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="billing_address_complement"
                                  id="billing_address_complement"
                                  value={billingAddress.billing_address_complement}
                                  className="form-control form-control-lg"
                                  placeholder={t("userProfile.tab1.additional-address-placeholder")}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="country">{t("userProfile.tab1.country")}</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="country"
                                  id="country"
                                  value={billingAddress.country}
                                  className="form-control form-control-lg"
                                  placeholder={t("userProfile.tab1.country-placeholder")}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="city">{t("userProfile.tab1.city")}</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  value={billingAddress.city}
                                  className="form-control form-control-lg"
                                  placeholder={t("userProfile.tab1.city-placeholder")}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="postal_code">{t("userProfile.tab1.postal-code")}</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="postal_code"
                                  id="postal_code"
                                  value={billingAddress.postal_code}
                                  className="form-control form-control-lg"
                                  placeholder={t("userProfile.tab1.postal-code-placeholder")}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </form>

                      </Tab>

                      <Tab eventKey="ChangePassword" title={t("userProfile.tab2.title")}>
                        {!isEditingPwd ? 
                        <form className='container d-flex flex-column gap-3 mt-6'>
                          <div className="col-12 col-lg-8">
                            <div className="form-group">
                              <label className="form-label" htmlFor="confirm_new_password">{t("userProfile.tab2.pwd")}</label>
                              <div className="form-control-wrap">
                                <input
                                  type={showConfirmNewPassword ? 'text' : 'password'}
                                  id="confirm_new_password" 
                                  name="confirm_new_password"
                                  value={generateUniqueId()}
                                  className="form-control form-control-lg"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className='d-flex gap-2'>
                            <button type="submit" className="btn btn-primary" onClick={onActiveEditting}>{t("buttons.edit")}</button>
                          </div>
                          <div className="col-12 col-lg-8">
                            <AlertBox color='success' message={message} visibility={isVisible}/>
                          </div>
                        </form>
                        :
                        <div className='container'>
                          <form className=' d-flex flex-column gap-5 mt-6 mb-3'>
                            <div className=" col-12 col-lg-8">
                              <div className="form-group">
                                <label className="form-label" htmlFor="current_password">{t("userProfile.tab2.actual-pwd")}</label>
                                <div className="form-control-wrap">
                                  <Link  to="#" onClick={toggleCurrentPasswordVisibility} className={`form-control-icon end password-toggle ${showCurrentPassword ? ' is-shown' : ''} `}  title="Toggle show/hide password">
                                    <em className="on icon ni ni-eye text-base"></em>
                                    <em className="off icon ni ni-eye-off text-base"></em>
                                  </Link>
                                  <input
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    id="current_password" 
                                    name="current_password"
                                    value={passwords.current_password}
                                    onChange={handleChange}
                                    className="form-control form-control-lg"
                                    placeholder={t("userProfile.tab2.actual-pwd-placeholder")}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-8">
                              <div className="form-group">
                                <label className="form-label" htmlFor="new_password">{t("userProfile.tab2.new-pwd")}</label>
                                <div className="form-control-wrap">
                                  <Link  to="#" onClick={toggleNewPasswordVisibility} className={`form-control-icon end password-toggle ${showNewPassword ? ' is-shown' : ''} `}  title="Toggle show/hide password">
                                    <em className="on icon ni ni-eye text-base"></em>
                                    <em className="off icon ni ni-eye-off text-base"></em>
                                  </Link>
                                  <input
                                    type={showNewPassword ? 'text' : 'password'}
                                    id="new_password" 
                                    name="new_password"
                                    value={passwords.new_password}
                                    onChange={handleChange}
                                    className="form-control form-control-lg"
                                    placeholder={t("userProfile.tab2.new-pwd-placeholder")}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-8">
                              <div className="form-group">
                                <label className="form-label" htmlFor="confirm_new_password">{t("userProfile.tab2.confirm-new-pwd")}</label>
                                <div className="form-control-wrap">
                                  <Link  to="#" onClick={toggleConfirmPasswordVisibility} className={`form-control-icon end password-toggle ${showConfirmNewPassword ? ' is-shown' : ''} `}  title="Toggle show/hide password">
                                    <em className="on icon ni ni-eye text-base"></em>
                                    <em className="off icon ni ni-eye-off text-base"></em>
                                  </Link>
                                  <input
                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                    id="confirm_new_password" 
                                    name="confirm_new_password"
                                    value={passwords.confirm_new_password}
                                    onChange={handleChange}
                                    className="form-control form-control-lg"
                                    placeholder={t("userProfile.tab2.confirm-new-pwd-placeholder")}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='d-flex gap-2'>
                              <button  className="btn btn-danger" onClick={onCancelEditing}>{t("buttons.cancel")}</button>
                              <button type="submit" className="btn btn-success" disabled={isDisabled} onClick={onConfirmPwdUpdate}>{t("buttons.confirm")} </button>
                            </div>
                            <div className="col-12 col-lg-8">
                              <AlertBox color='danger' message={message} visibility={isVisible}/>
                            </div>

                          </form>
                        </div>
                      }
                      </Tab>
                    </Tabs>
                  </div>
                </div>
          </div>
        </div>
  );
};

export default UserProfile;
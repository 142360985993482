import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context
interface ChatContextType {
  activeChat: string;
  setActiveChat: (chat: string) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

// Create a provider component
interface ChatProviderProps {
  children: ReactNode; // Accept the children prop
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [activeChat, setActiveChat] = useState("ALE");

  return (
    <ChatContext.Provider value={{ activeChat, setActiveChat }}>
      {children}
    </ChatContext.Provider>
  );
};

// Create a custom hook for using the context
export const useChat = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};

import React, {useEffect, useState} from 'react';
import Section from '../../components/UI/Section/Section';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../redux';
import {useNavigate} from 'react-router';
import {signupService} from '../../services/auth.service';
import {Link, useLocation} from "react-router-dom";
import LOGO_IMG from '../../assets/images/logo-no-text.png'
import { useTranslation } from 'react-i18next';
import AlertBox from '../../components/UI/AlertBox/AlertBox';
import {getPasswordStrengh, isPasswordsMatch } from '../../utils';



export default function SignupCopl() {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [isParticular, setIsParticular] = useState(false);
    const [hasAllianceComAccount, setHasAllianceComAccount] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const licence = params.get('licence');
    const companyName :any = params.get('compagny_name');

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        gender: '',
        phone_number: '',
        confirm_password: '',
        // company related info
        company_name: companyName,
        alliance_com_number: '',
        has_alliance_com_account: hasAllianceComAccount,
        vat_number: '',
        // billing address
        billing_address: '',
        billing_address_complement: '',
        postal_code: '',
        city: '',
        country: '',
        is_particular: isParticular,
        licence_id: licence,
    });

    const {length, hasLowercase, hasUppercase, hasNumber, hasSpecialCharacter, nbValid} = getPasswordStrengh(formData.password)

    useEffect(() => {
        setFormData({
            ...formData,
            is_particular: isParticular,
            has_alliance_com_account: hasAllianceComAccount
        });
    }, [hasAllianceComAccount, isParticular]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleCompanyCheck = () => {
        setIsParticular(!isParticular);
        setHasAllianceComAccount(false);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(signupService(formData))
        .then(()=>{
            setIsSuccess(true);
            setMessage(`${t("signup.msg-account-created")}`);
            setIsVisible(true);
          })
          .catch((error)=>{
            setIsSuccess(false);
            setMessage(error);
            setIsVisible(true);
          });
    };

    return ( 
    <>    
        {!isSuccess &&   
            <Section classNames='section has-mask'>
                <div className="nk-shape bg-shape-blur-a  start-50 top-50 translate-middle"></div>
                <div className="my-auto">
                    <div className="container">
                        <div className="section-head pb-5">
                            <div className="row justify-content-center text-center">
                            <div className="col-md-7 col-lg-6 col-xl-5">
                                <Link to={'/'} className='allianceLogoLink' >
                                <img className="h-5rem mb-3 " src={LOGO_IMG}  alt=""/>
                                </Link>   
                                <h4 className="title ">AllianceCom</h4>
                                <h3 className="title">{t("signup.title")} </h3>
                            </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs justify-content-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card border-0 shadow-sm rounded-4 mb-3">
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row g-3">
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="firstname">{t("signup.fname")}</label>
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="text"
                                                                    id="first_name"
                                                                    name="first_name"
                                                                    value={formData.first_name}
                                                                    onChange={handleChange}
                                                                    className="form-control form-control-lg"
                                                                    placeholder={t("signup.fname-placeholder")}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="lastname">{t("signup.lname")}</label>
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="text"
                                                                    id="last_name"
                                                                    name="last_name"
                                                                    value={formData.last_name}
                                                                    onChange={handleChange}
                                                                    className="form-control form-control-lg"
                                                                    placeholder={t("signup.lname-placeholder")}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="gender">{t("signup.sexe")}</label>
                                                            <select
                                                                id="gender"
                                                                name="gender"
                                                                onChange={handleChange}
                                                                className="form-control form-control-lg"
                                                                defaultValue=''
                                                            >
                                                                <option value=''>{t("signup.sexe-placeholder")}</option>
                                                                <option value={'Male'}>{t("signup.sexe-male")}</option>
                                                                <option value={'Female'}>{t("signup.sexe-female")}</option>
                                                                <option value={'Other'}>{t("signup.sexe-other")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group">
                                                        <label className="form-label" htmlFor="telNumber">{t("signup.phone")}</label>
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="tel"
                                                                    id="phone_number"
                                                                    name="phone_number"
                                                                    value={formData.phone_number}
                                                                    className="form-control form-control-lg"
                                                                    placeholder={t("signup.phone-placeholder")}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="emailaddress">{t("signup.email")} </label>
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    name="email"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                    className="form-control form-control-lg"
                                                                    placeholder={t("signup.email-placeholder")}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="toggle-password">{t("signup.pwd")}</label>
                                                            <div className="form-control-wrap">
                                                                <Link  to='#' onClick={togglePasswordVisibility} className="form-control-icon end password-toggle"
                                                                    title="Toggle show/hide password">
                                                                    <em className="on icon ni ni-eye text-base"></em>
                                                                    <em className="off icon ni ni-eye-off text-base"></em>
                                                                </Link>
                                                                <input
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    id="password"
                                                                    name="password"
                                                                    value={formData.password}
                                                                    onChange={handleChange}
                                                                    className={`form-control form-control-lg  ${ !!formData.password && nbValid>2 && nbValid <4 ? 'border border-warning' : !!formData.password && nbValid <=2 ? 'border border-danger' : '' }`}
                                                                    placeholder={t("signup.pwd-placeholder")}
                                                                    minLength={8}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {  !!formData.password && nbValid==4 ? 
                                                            <label className='text-success mx-2 mt-2'>{t("signup.pwd-strong")}</label>
                                                            : !!formData.password && nbValid>2 ? 
                                                            <label className='text-warning mx-2 mt-2'>{t("signup.pwd-medium")}</label>
                                                            : !!formData.password && nbValid <=2 ? 
                                                            <label className='text-danger mx-2 mt-2'>{t("signup.pwd-weak")}</label>
                                                            :
                                                            null
                                                        }
                                                        <ul className="list  p-2">

                                                            <li className={`smaller ${hasLowercase && hasUppercase? "text-success" : ""}`}>
                                                            <em className="icon ni ni-check-circle-fill"></em>
                                                            <span className="low-upper-case">
                                                                <i className="fa fa-file-text  " aria-hidden="true"></i>
                                                            </span>{t("signup.pwd-rules.1")}
                                                            </li>

                                                            <li className={`mt-1 smaller ${hasNumber? "text-success" : ""}`}>
                                                            <em className="icon ni ni-check-circle-fill "></em>
                                                            <span className="one-number">
                                                                <i className="fa fa-file-text" aria-hidden="true"></i>
                                                            </span>{t("signup.pwd-rules.2")}
                                                            </li>

                                                            <li className={`mt-1 smaller ${hasSpecialCharacter? "text-success" : ""}`}>
                                                            <em className="icon ni ni-check-circle-fill "></em>
                                                            <span className="one-special-char">
                                                                <i className="fa fa-file-text" aria-hidden="true"></i>
                                                            </span>{t("signup.pwd-rules.3")}
                                                            </li>

                                                            <li className={`mt-1 smaller ${length? "text-success" : ""}`}>
                                                            <em className="icon ni ni-check-circle-fill"></em>
                                                            <span className="">
                                                                <i className="fa fa-file-text" aria-hidden="true"></i>
                                                            </span>{t("signup.pwd-rules.4")}
                                                            </li>

                                                        </ul>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="toggle-password-confirm">{t("signup.pwd-confirmation")}</label>
                                                            <div className="form-control-wrap">
                                                                <Link to="#" onClick={toggleConfirmPasswordVisibility} className="form-control-icon end password-toggle" title="Toggle show/hide password">
                                                                    <em className="on icon ni ni-eye text-base"></em>
                                                                    <em className="off icon ni ni-eye-off text-base"></em>
                                                                </Link>
                                                                <input
                                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                                    id="toggle-password-confirm"
                                                                    name="confirm_password"
                                                                    value={formData.confirm_password}
                                                                    onChange={handleChange}
                                                                    className={`form-control form-control-lg ${!isPasswordsMatch(formData.password, formData.confirm_password)?'border border-danger':'' }`}
                                                                    placeholder={t("signup.pwd-confirmation-placeholder")}
                                                                    minLength={8}
                                                                    required
                                                                />
                                                            </div>
                                                            {!isPasswordsMatch(formData.password, formData.confirm_password)&&<label className='text-danger  mx-2 mt-2'>{t("signup.pwd-match")}</label>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="admin-type">{t("signup.step2")}</label>
                                                            <div className="form-control-wrap">
                                                                { companyName !== ""  &&
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="particular-admin"
                                                                            className="form-check-input"
                                                                            checked={isParticular}
                                                                            onChange={handleCompanyCheck}
                                                                            disabled={companyName !== ""}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="particular-admin">{t("signup.individual")}</label>
                                                                    </div>
                                                                }
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="company-admin"
                                                                        className="form-check-input"
                                                                        checked={!isParticular}
                                                                        onChange={handleCompanyCheck}
                                                                        disabled={companyName !== ""}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="company-admin">{t("signup.company")}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="company-name">
                                                                {t("signup.social-reason")}                 
                                                            </label>
                                                            <div className="form-control-wrap">
                                                                <input
                                                                    type="text"
                                                                    id="company_name"
                                                                    name="company_name"
                                                                    disabled
                                                                    className="form-control form-control-lg"
                                                                    value={companyName}
                                                                    placeholder={t("signup.social-reason-placeholder")} 

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="form-check form-check-sm">
                                                                <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value=""
                                                                id="iAgree"
                                                                required
                                                                />
                                                                <label className="form-check-label small" htmlFor="iAgree">
                                                                    {t("signup.accept-terms")}
                                                                    <Link to="/privacy-policy">{t("signup.privacy")}</Link> {t("signup.and")}
                                                                    <Link to="/terms-of-service" target="_blank">{t("signup.conditions")}</Link>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <button id="submit-btn" className="btn btn-primary btn-block" type="submit" >{t("buttons.signup")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <AlertBox color='danger' message={message} visibility={isVisible}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        }
        { isSuccess &&   
          <Section classNames="section has-mask ">
            <div className="my-auto">
              <div className="container">
                <div className="section-head pb-5">
                  <div className="row justify-content-center text-center">
                    <div className="row justify-content-center text-center mb-8">
                      <div className="col-md-8 col-lg-10 col-xl-12">
                        <Link to={'/'} className='allianceLogoLink' >
                          <img className="h-5rem mb-3 " src={LOGO_IMG}  alt=""/>
                        </Link>   
                        <h4 className="title">AllianceCom</h4>
                        <h3 className="title mt-8">{t("signup.msg-ready")}</h3>
                      </div>
                    </div>
                    <div className="section-content text-center">
                      <div className="row g-gs justify-content-center">
                        <div className="col-lg-6 col-md-12">
                          <Link 
                            className="btn btn-lg btn-primary rounded-pill mb-3" 
                            to="/signin">
                            <span>{t("buttons.connect-now")}</span>
                            <em className="icon ni ni-arrow-long-right"></em>
                          </Link>
                        </div>
                        <AlertBox color={isSuccess?'success':'danger'} message={message} visibility={isVisible}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </Section>
        }
    </>    
    )
    ;
}

import * as types from '../actions/actionsTypes';
import { FETCH_SUBSCRIPTIONS } from '../actions/actionsTypes';

// Define the shape of the licenses state

const initialState: any[] = [];

const subscriptions = (state: any[] = initialState, action: any) => {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTIONS:
      // Update the state with the fetched licenses
      return action.payload.subscriptions;
      
    default:
      return state;
  }
};

export default subscriptions;






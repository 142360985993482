import React from 'react'
import Section from '../../components/UI/Section/Section'

import NOTFOUNG_IMG from '../../assets/images/gfx/error/404.svg'
import { useTranslation } from 'react-i18next';

export default function NotFound() {
    const { t } = useTranslation();
    
  return (
      <section className='section' >
        <div className='container'>
            <div className="row g-gs justify-content-center">
                <div className="col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                    <div className="block-gfx">
                        <img className="w-100 rounded-4" src={NOTFOUNG_IMG} alt="not found 404 image"/>
                    </div>
                    <div className="block-text text-center mt-4 ">
                        <h1 className="title">{t("not-found.title")}</h1>
                        <h3 className="title">{t("not-found.sub-title")}</h3>
                        <p>{t("not-found.description")}</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
  )
}
import Plans from "../../../components/UI/Plans/Plans";
import { Link } from "react-router-dom";
import { useChat } from "../../../contexts/ChatProvider";
import { SparkleFilled } from "@fluentui/react-icons";
import useLoggedinUser from "../../../hooks/useLoggedinUser";
import { useTranslation } from "react-i18next";

export default function MemberDashboard() {
  const { t } = useTranslation();
  const { setActiveChat } = useChat();
  const { USER } = useLoggedinUser();

  const handleNavigation = () => {
    setActiveChat('ALE');
    window.localStorage.setItem('activeChat', 'ALE');
  };

  return (
    USER.hasSubscriptions &&
    USER.hasAssignedLicence &&
    !["CANCELED_BY_ADMIN", "CANCELED_BY_USER", "SUSPENDED"].includes(USER.subscriptions[0].status) ? (
      <section className='section'>                    
        <div className='text-center'>
          <div className='w-100 text-center'>
            <SparkleFilled fontSize={"120px"} primaryFill={"rgba(8,160,198,1)"} aria-hidden="true" aria-label="Chat logo" />
          </div>
          <h2 className='chatEmptyStateTitle text-center'>{t("users-dashboards.start")}</h2>
          <h1 className='chatEmptyStateTitle text-center'>{t("users-dashboards.ask")}</h1>
          <Link to='/chat' onClick={handleNavigation} className='btn btn-primary btn-lg rounded-pill mt-5'>{t("users-dashboards.discussion")}</Link>
        </div>
      </section>
    ) : (
      <section className='section'>
        <div className='container'>
          <Plans />
        </div>
      </section>
    )
  );
}
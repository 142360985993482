export const URL = {
  // LOGIN
  API_LOGIN: "/users/login",

  // SIGNUP
  API_SIGNUP: "/users/signup",

  // CREATE A SUBSCRIPTION PAYMENT 
  API_CREATE_SUBSCRIPTION: "/users/subscriptions/create",

  // LICENCES
  API_LICENCE: "/licences",
  API_LICENCE_ASSIGNMENT: "/licences/assign-licence",

  // USERS
  API_USERS_ME: "/users/me",
  API_USER_STRIPE_PORTAL: "/users/stripe-portail",
  API_USER_CHANGE_PWD: "/users/change-password",

  // PASSWORD 
  API_PWD_GET_RESET_LINK: "/password/get-link",
  API_PWD_RESET: "/password/new-password",
};

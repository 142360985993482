import { useEffect } from 'react';

export default function Spinner() {
  useEffect(() => {
    // Prevent scrolling when the component mounts
    document.body.style.overflow = 'hidden';

    // Restore the original overflow when the component unmounts or when the spinner is hidden
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []); // Run this effect only once when the component mounts

  return (
    <div className=" spinner position-fixed top-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ background: 'rgba(0, 0, 0, 0.8)' , zIndex:1000}}>
      <div className="spinner-border text-primary" style={{ width: "5rem", height: "5rem"}} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};


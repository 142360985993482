import React from 'react'

/**
 * Item Component
 * @param {string} title - Item Title.
 * @param {string} icon - icon bootstrap classe .
 * @param {string} color - icon color .

 */

//  Interface for  Item props
interface ItemProps {
  title:string;
  icon:string;
  color:string;
}

export default function Item({title, icon, color}:ItemProps) {
  return (
    <>
      <em className={`icon text-${color} fs-5 ni ${icon}`}></em>
      <span>{title}</span>
    </>
  )
}

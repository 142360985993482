import axios from "axios";
import {URL} from "../api/constants";
import subscriptions from '../redux/reducers/subscriptions';
import { AppDispatch } from "../redux";
import { fetchSubscriptions } from "../redux/actions";
import { getConfig } from "./config.service";

//Create  Subscriptions  (Payment intent)
export const createSubscriptionService = async (product_id:string, quantity :string, accessToken:string ) => {

  let config =await getConfig();
  let API_HOST_URL = config.API_HOST_URL;
  
  try {
    const response = await axios.post(API_HOST_URL+URL.API_CREATE_SUBSCRIPTION,null,{
      params:{
        stripe_product_id:product_id,
        quantity:quantity,
      },
      headers: {
        'Content-Type': ' application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
     });

    return response.data;

  } catch (error:any) {
      throw error.response.data;
    }
};

//Fetch Subscriptions 
export const fetchSubscriptionsService =  (subscriptions:any)=> async (dispatch: AppDispatch)=> {

  return dispatch(fetchSubscriptions(subscriptions));

}



import React from 'react'
import UserProfile from '../../components/UserProfile/UserProfile'

export default function Profile() {
  return (
    <div className='section flex-grow-1'>
        <UserProfile/>
    </div>
  )
}

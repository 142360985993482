import * as types from './actionsTypes';


//#region AUTHENTICATION Actions
  // Signin
  export const signin = (user: any) => ({
    type: types.SIGNIN,
    payload: {
      user, 
    },
  });

  // Signup
  export const signup = () => ({
    type: types.SIGNUP,
    payload: {},
  });
//#endregion

  // Logout
  export const logout = () => ({
    type: types.LOGOUT,
  });
//#endregion

//#region USERS Actions
  // fetch users/me
  export const fetchUserMe = (user:any) => ({
    type:types.FETCH_USER_ME,
    payload: {
      user,
    },
  });
//#endregion



//#region SUBSCRIPTIONS Actions
  // Fetch subscriptions
  export const fetchSubscriptions = (subscriptions:any) => ({
    type: types.FETCH_SUBSCRIPTIONS,
    payload: {
      subscriptions
    },
  });
//#endregion


//#region LICENCES Actions
  // Fetch licences
  export const fetchLicences = (licences:any) => ({
    type: types.FETCH_LICENCES,
    payload: {
      licences, 
    },
  });

  // Action to assign a license
  export const assignLicence = (licence: any) => ({
    type: types.ASSIGN_LICENCE,
    payload: {
      licence,
    },
  });

  // Action to remove assignment of a license
  export const removeLicenceAssignment = (licenceId: number) => ({
    type: types.REMOVE_ASSIGNMENT,
    payload: {
      licenceId,
    },
  });
  // Action to add a new license
  export const addLicence = (licence: any) => ({
    type: types.ADD_LICENCE,
    payload: {
      licence,
    },
  });
  // Action to delete a license permanently
  export const deleteLicence = (licenceId: number) => ({
    type: types.DELETE_LICENCE,
    payload: {
      licenceId,
    },
  });
//#endregion

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Plans() {
    const { t } = useTranslation();

  return (

      <div className="row g-gs align-items-end justify-content-center">
        <div className="col-xxl-5 col-xl-5 has-mask">
            <div className="pricing h-100 pricing-featured bg-gradient-primary">
                <div className="pricing-body h-100 p-5 pt-3 p-md-0 pt-md-0 p-xl-5 pt-xl-3 d-md-flex d-xl-block">
                    <div className="text-center p-md-5 p-xl-0 w-md-50 w-xl-100">
                        <div className="badge bg-gradient-primary bg-opacity-20 gradient-angle-90 mb-4 px-3 py-2 rounded-pill small text-primary text-tracking-1">
                            <div className="p-1">{t('plans.box_1.tag')}</div>
                        </div>
                        <h3 className="mb-3">{t('plans.box_1.title')}</h3>
                        <div className="pricing-price-wrap pricing-toggle-content">
                            <div className="pricing-price monthly">
                                <h3 className="display-5 mb-3">9,50€ <span className="caption-text text-muted">{t('plans.box_1.excl_tax')} / {t('plans.box_1.month')} </span></h3>
                            </div>
                            {/* <div className="pricing-price yearly">
                                <h3 className="display-5 mb-3">99€ <span className="caption-text text-muted"> / yearly </span></h3>
                            </div> */}
                        </div>
                        <p className="fw-bold text">{t('plans.box_1.contract')}</p>

                        <p className="fw-bold text-primary">{t('plans.box_1.subtitle')}</p>
                        <div className="bg-light px-4 py-2 mb-4 smaller rounded-1">{t('plans.box_1.paragraph')}</div>
                        <div className="px-4 pricing-toggle-content">
                            <div className="pricing-toggle-option monthly">
                                <Link to="/cart" className="btn btn-primary btn-block rounded-pill">{t('plans.box_1.button')}</Link>
                            </div>
                            {/* <div className="pricing-toggle-option yearly">
                                <Link to="#" className="btn btn-primary btn-block rounded-pill">Get Committed</Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="d-none d-md-block d-xl-none border-start h-100"></div>
                    <div className="pt-4 p-md-5 p-xl-0 pt-xl-4 w-md-50 w-xl-100">
                        <ul className="list gy-3">
                            <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_1.dot_1')}</span></li>
                            <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_1.dot_2')}</span></li>
                            <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_1.dot_3')}</span></li>
                            <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_1.dot_4')}</span></li>
                            <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_1.dot_5')}</span></li>
                            <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_1.dot_6')}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-xxl-5 col-xl-5 has-mask">

            <div className="h-100 pt-xl-6">
                <div className="pricing h-100">
                    <div className="pricing-body h-100 p-5 pt-3 p-md-0 pt-md-0 p-xl-5 pt-xl-3 d-md-flex d-xl-block">
                        <div className="text-center p-md-5 p-xl-0 w-md-50 w-xl-100">
                            <h3 className="mb-3">{t('plans.box_2.title')}</h3>
                            {/* <h3 className="display-5 mb-3">-<span className="caption-text text-muted"> / month</span></h3> */}
                            <div className="media media-middle media-2xl bg-light rounded-pill mb-4 mt-3">
                                <em className="icon ni ni-building"></em>
                            </div>
                            <p className="fw-bold">{t('plans.box_2.subtitle_1')}</p>
                            <div className="bg-light px-4 py-2 mb-4 smaller rounded-1">{t('plans.box_2.paragraph')}</div>
                            <div className="px-4">
                                <Link to="mailto:contact@alliance-com.ai" className="btn btn-outline-primary btn-block rounded-pill">{t('plans.box_2.button')}</Link>
                            </div>
                        </div>
                        <div className="d-none d-md-block d-xl-none border-start h-100"></div>
                         <div className="pt-4 p-md-5 p-xl-0 pt-xl-4 w-md-50 w-xl-100">
                            <h5 className="fw-medium pt-4 pb-1">{t('plans.box_2.subtitle_2')}</h5>
                            <ul className="list gy-3 mt-3">
                                <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_2.dot_1')}</span></li>
                                <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_2.dot_2')}</span></li>
                                <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_2.dot_3')}</span></li>
                                <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_2.dot_4')}</span></li>
                                <li><em className="icon fs-4 ni ni-check-circle-fill text-info"></em> <span>{t('plans.box_2.dot_5')}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  )
}

import * as types from '../actions/actionsTypes';

// Define the shape of the licenses state
interface UsersState {
  me: any | null; // Update the type as per your user object structure
  // users: any[];
}

// Initial state for authentication
const initialState: UsersState = {
  me: {},
  // users: [],
  // You can add other relevant properties here
};

const users = (state: UsersState = initialState, action: any) => {
  switch (action.type) {
    
    case types.FETCH_USER_ME:
      return {
        ...state,
        me: action.payload.user,
      };

    default:
      return state;
  }
};

export default users;






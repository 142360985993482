import {loadStripe} from '@stripe/stripe-js';
import {Elements,} from '@stripe/react-stripe-js';
import {useLocation} from 'react-router';
import CheckoutForm from './CheckoutForm';
import {useContext} from "react";
import {GlobalContext} from "../../contexts/global";
import './Checkout.css';
import { useTranslation } from 'react-i18next';

export default function Checkout() {
  const { i18n} = useTranslation();
  const { config } = useContext(GlobalContext)
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY,{
    locale:"auto",    
  });
  const location = useLocation();
  const clientSecret = location?.state?.clientSecret;
  
  // Ensure that clientSecret is available
  if (!clientSecret) {
    // You may want to handle the case where clientSecret is not available, e.g., redirect or show an error message.
    return <div> Error: Missing clientSecret</div>;
  }

  const options = {
    clientSecret: clientSecret,
    // other options as needed
  };

  return (
    <Elements stripe={stripePromise} options={options} >
      <CheckoutForm />
    </Elements>
  );
}

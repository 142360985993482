import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import MemberDashboard from "./UsersDashboards/MemberDashboard";
import SuperAdminDashboard from "./UsersDashboards/SuperAdminDashboard";
import OrgAdminDashboard from "./UsersDashboards/OrgAdminDashboard";
import OrgMemberDashboard from "./UsersDashboards/OrgMemberDashboard";
import { useEffect, useState } from "react";
import { fetchLicencesService } from "../../services/licences.service";
import { fetchSubscriptionsService } from "../../services/subscription.service";
import { fetchUsersMeService } from "../../services/users.service";
import { useNavigate } from "react-router-dom";
import { logoutService } from "../../services/auth.service";
import InactivityTimer from "../../components/InactivityTimer/InactivityTimer";
import useLoggedinUser from "../../hooks/useLoggedinUser";
import Spinner from "../../components/UI/Spinner/Spinner";

export default function  Dashboard  () {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { USER } = useLoggedinUser();
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  //Store Selectors
  const accessToken = useSelector((state: RootState) => state.auth.userData.access_token);

  useEffect(() => {
    dispatch(fetchUsersMeService(accessToken))
      .then((response) => {
        dispatch(fetchSubscriptionsService(response.payload.user.subscriptions))
          .then((response) => {
            const allLicences: any = [];
            response.payload.subscriptions.forEach((subscription: any) => {
              const { product, licences } = subscription;
              licences.forEach((licence: any) => {
                const licenceObj = {
                  ...licence,
                  product,
                };
                allLicences.push(licenceObj);
              });
            });
            dispatch(fetchLicencesService(allLicences));
          })
          .catch(error => {
            throw(error);
          })
          .finally(() => {
            setIsLoading(false); // Set loading state to false when data fetching is complete
          });
      })
      .catch(error => {
        setIsLoading(false); // Set loading state to false on error
        throw(error);
      });
  }, [accessToken, dispatch]);

    const handleLogout = () => {
      dispatch(logoutService()).then(()=>{
        navigate('/');
      });
    };
    
  let dashboardComponent;
  // Based on the user's role, assign the appropriate component to the dashboardComponent variable
  if (USER.isParticular) {
    dashboardComponent = <MemberDashboard />;
  } else if (USER.isSuperAdmin) {
    dashboardComponent = <SuperAdminDashboard />;
  } else if (USER.isOrgAdmin) {
    dashboardComponent = <OrgAdminDashboard />;
  } else {
    dashboardComponent = <OrgMemberDashboard />;
  }

  return (
    isLoading 
      ? <Spinner/>
      // Render the dashboard component
      : <>
          { USER.isParticular 
            ? <MemberDashboard />
            : USER.isSuperAdmin 
            ? <SuperAdminDashboard />
            : USER.isOrgAdmin 
            ? <OrgAdminDashboard />
            : <OrgMemberDashboard />
          }
          <InactivityTimer onLogout={() => handleLogout()} />
        </>
  );
};

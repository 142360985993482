import React from 'react'
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
    const { t } = useTranslation();

  return (
    <section className="section ">
      <div className="nk-mask  mt-n5 mb-10p mh-50vh"></div>
      <div className="container">
          <div className="section-content">
              <div className="row g-gs justify-content-center">
                  <div className="col-xxl-8 col-xl-9 col-lg-10">
                      <div className="text-center mb-6">
                          <h2 className="title">{t("privacy_policy.title")}</h2>
                          <ul className="list list-row gx-2">
                              <li>
                                  <div className="sub-text fs-5">{t("privacy_policy.last_updated")}</div>
                              </li>
                          </ul>
                      </div>
                      <div className="block-typo">
                        <h3>{t("privacy_policy.sections.identity_contact.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("privacy_policy.sections.identity_contact.content")}</p>
                        <a href='https://azure.microsoft.com/fr-fr/support/legal/'>{t("privacy_policy.sections.identity_contact.link1")}</a>
                        <br/>
                        <a href='https://www.microsoft.com/fr-fr/trust-center/privacy'>{t("privacy_policy.sections.identity_contact.link2")}</a>                          
                        <br/>
                        <br/>
                        <h3>{t("privacy_policy.sections.data_protection.title")}</h3>
                        <h4><u>{t("privacy_policy.sections.data_protection.subtitle1")}</u></h4>                          
                        <p style={{whiteSpace:'pre-wrap'}}>{t("privacy_policy.sections.data_protection.content1")}</p>
                        <h4><u>{t("privacy_policy.sections.data_protection.subtitle2")}</u></h4>                          
                        <p style={{whiteSpace:'pre-wrap'}}>{t("privacy_policy.sections.data_protection.content2")}</p>
                        <br/>
                        <h3>{t("privacy_policy.sections.applicable_law.title")}</h3>
                        <p>{t("privacy_policy.sections.applicable_law.content")}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
  )
}

import { useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAsyncLoader } from '../../hooks/useAsyncLoader';
import { AppDispatch, RootState } from '../../redux';
import { addLicenceService, assignLicenceService, removeLicenceAssignementService, deleteLicenceService } from '../../services/licences.service';
import { ModalBs } from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';
import { useTranslation } from 'react-i18next';



export default function LicencesManager() {
  // hooks 
  const {handler, errors, isLoading}=useAsyncLoader(); // hook to manage asyncronous callback 
  const dispatch: AppDispatch = useDispatch();  // Dispatch 
  const {t}= useTranslation(); // Translations 
  // Store Selector 
  const accessToken = useSelector((state: RootState) => state.auth.userData.access_token);
  const licences = useSelector((state: RootState) => state.licences);
  const subscriptions = useSelector((state: RootState) => state.subscriptions);
  // States
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [showAsignmentModal, setShowAssignmentModal] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [userEmail, setUserEmail] =  useState('');
  const [selectedLicence, setSelectedLicence] =  useState<any>(null);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  // constants 
  const MAX_LICENSES=100; // to limit the number of licenses an Admin can add by subscription
  const pageSize = 10; // datatable page size 

  // Function to handle page changes
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  // Function to render the action buttons depending on the license status
  const renderActionButtons = (licence: any) => {
      const {status} = licence;
    if (status=='ASSIGNED'|| status=='INVITATION_SEND') {
      return (
        <>
          <button 
            className="btn btn-sm rounded-pill btn-primary me-2" 
            onClick={()=> {
              setShowAssignmentModal(true);
              setSelectedLicence(licence);
              }
            }>
            {t("buttons.re-assign")}
          </button>

          <button 
            className="btn btn-sm rounded-pill btn-danger me-5" 
            onClick={()=> {
              setShowWithdrawalModal(true);
              setSelectedLicence(licence);
            }}
            disabled={licence.status==('AVAILABLE')}
            >
            {t("buttons.withdraw")}
          </button>
          <Link 
            className="link  link-light " 
            onClick={()=> {
              setShowDeletionModal(true);
              setSelectedLicence(licence);
            }}
            to=''
            >
            {t("buttons.delete")}
          </Link>
        </>
      );
    } else if (status=='AVAILABLE') {
      return (
        <>
          <button 
            className="btn btn-sm rounded-pill btn-success me-2" 
            onClick={()=> {
              setShowAssignmentModal(true);
              setSelectedLicence(licence);
              }
            }>
            {t("buttons.assign")}
          </button>

          <button 
            className="btn btn-sm rounded-pill btn-danger  me-5" 
            onClick={()=> {
              setShowWithdrawalModal(true);
              setSelectedLicence(licence);
            }}
            disabled={licence.status==('AVAILABLE')}
            >
            {t("buttons.withdraw")}
          </button>
          <Link 
            className="link  link-light " 
            onClick={()=> {
              setShowDeletionModal(true);
              setSelectedLicence(licence);
            }}
            to=''
            >
            {t("buttons.delete")}
          </Link>
        </>
      );
    }
  };

  const renderStatusBadges = (status:string) => {

    if (status == "AVAILABLE")
    return (
      <span className="badge text-bg-success-soft">
        {t("licenses-manager.badges.available")}
      </span>)
    else if (status== "INVITATION_SEND")
    return (
      <span className="badge text-bg-danger-soft">
        {t("licenses-manager.badges.invitation-sent")}
      </span>)
    else 
    return (
      <span className="badge text-bg-warning-soft">
        {t("licenses-manager.badges.assigned")}
      </span>)
  }

  // Function to filter licenses based on search term
  const filteredLicences = licences.filter((licence:any) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      licence.id.toString().includes(searchTermLower) ||
      licence.product.name.toLowerCase().includes(searchTermLower) ||
      licence.status.toLowerCase().includes(searchTermLower) 
      // ||
      // licence.user .email.toLowerCase().includes(searchTermLower)
    );
  });

  // Calculate the start and end indexes for the current page
  const startIndex = (activePage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Slice the filtered licenses to display only the current page
  const licencesToDisplay = filteredLicences.slice(startIndex, endIndex);

  // hide the modal
  const onHide = () => {
    setShowWithdrawalModal(false);
    setShowAssignmentModal(false);
    setShowDeletionModal(false);
    setSelectedLicence(null);
    setUserEmail('');
  }; 

  // Confirm Licence Addition     
  const onConfirmLicenceAddition = () => {
    handler(() => dispatch(addLicenceService(subscriptions[0], accessToken))
      .then((response) => {
        console.log('Added licence', response);
      })
      .catch((error) => {
        throw error;
      })
    );
  }
  // Confirm Licence Assignment     
  const onConfirmAssignment = () => {
    handler(()=>dispatch(assignLicenceService(selectedLicence.id, userEmail, accessToken)))
      .then(()=>{
        setShowAssignmentModal(false);
        setSelectedLicence(null);
        setUserEmail('')
      })
      .catch((error) => {
        throw error;
      });
  }

// Confirm Licence Deletion     
const onConfirmAssignmentRemoval = () => {
  handler(()=>dispatch(removeLicenceAssignementService(selectedLicence.id, accessToken)))
    .then(()=>{
      setShowWithdrawalModal(false);
      setSelectedLicence(null);
    })
    .catch((error) => {
      throw error;
    });
}

// Confirm Licence Deletion     
const onConfirmDeletion = () => {
  handler(()=>dispatch(deleteLicenceService(selectedLicence.id, accessToken)))
    .then(()=>{
      setShowDeletionModal(false);
      setSelectedLicence(null);
    }
  );
}

// Licence Assignment Form 
const LicenceAsignmentForm = ()=>
  <div className="">
    <p>{t("licenses-manager.msg-assignment")}</p>
    <input
      type="email"
      className="form-control"
      placeholder="user@example .com"
      value={userEmail}
      onChange={(e) => setUserEmail(e.target.value)}
    />
  </div>  
  return (
     isLoading 
     ? <Spinner/>
     : <>
        <section className="section">
          <div className="container">
            <div className="section-content mb-5">
              <div className="row g-gs">
                {/* User and License count */}
                <div className="d-flex flex-row align-items-end">
                  <div className="border-0 h-100 w-100 d-flex align-items-center gap-3">
                    <div className="media media-middle media-xl text-info bg-info bg-opacity-20 rounded-3">
                      <em className="icon ni ni-award"></em>
                    </div>
                    <h4 className="display-6">
                        {filteredLicences.length}{" "}
                        <span className="caption-text fs-3">
                            {t("licenses-manager.license", { count: filteredLicences.length }).toUpperCase()}
                        </span>
                    </h4>
                  </div>
                  <div className="w-100 h-50 d-flex justify-content-end  align-items-center">
                    <button  className="btn  btn-lg btn-success rounded-pill" onClick={onConfirmLicenceAddition} disabled={licences.length>MAX_LICENSES} >
                      <span className='d-flex gap-2 align-items-center'>
                        <i className="ni ni-plus-circle-fill fs-4  "></i>{t("buttons.add-license")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Search input */}
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={t("licenses-manager.search-placeholder")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* Licenses table */}
            <table className=" table table-tertiary table-hover">
              <thead>
                <tr className='text-center'>
                  <th>{t("licenses-manager.id")}</th>
                  <th>{t("licenses-manager.license")}</th>
                  <th>{t("licenses-manager.status")}</th>
                  <th>{t("licenses-manager.assignment")}</th>
                  <th>{t("licenses-manager.actions")}</th>
                </tr>
              </thead>
              <tbody className='text-center'>
                {licencesToDisplay.map((licence:any) => (
                  <tr key={licence.id}>
                    <td>{licence.id}</td>
                    <td>{licence.product.name}</td>
                    <td>{renderStatusBadges(licence.status)}</td>
                    <td>{licence.user ? licence.user.email : '-' }</td>
                    <td className='actions-btns '>{renderActionButtons(licence)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination */}
            <Pagination className="pagination-md primary">
              <Pagination.Prev
                onClick={() => handlePageChange(activePage - 1)}
                disabled={activePage === 1}
              />
              {Array.from({
                length: Math.ceil(filteredLicences.length / pageSize),
              }).map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === activePage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(activePage + 1)}
                disabled={
                  activePage === Math.ceil(filteredLicences.length / pageSize) ||
                  filteredLicences.length === 0
                }
              />
            </Pagination>
          </div>
        </section>

        <ModalBs  show={showWithdrawalModal} title={t("licenses-manager.modal-withdrawal")} modalContent={t("licenses-manager.msg-withdrawal")} onHide={onHide} onConfirm={onConfirmAssignmentRemoval}/>
        <ModalBs  show={showAsignmentModal} title={t("licenses-manager.modal-assignment")} modalContent={LicenceAsignmentForm()} onHide={onHide} onConfirm={onConfirmAssignment} />
        <ModalBs  show={showDeletionModal} title={t("licenses-manager.modal-deletion")}  modalContent={t("licenses-manager.msg-deletion")} onHide={onHide} onConfirm={onConfirmDeletion}/>
    </>
  
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next';

export default function TermsOfService() {
    const { t } = useTranslation();

  return (
    <section className="section ">
      <div className="nk-mask  mt-n5 mb-10p mh-50vh"></div>
      <div className="container">
          <div className="section-content">
              <div className="row g-gs justify-content-center">
                  <div className="col-xxl-8 col-xl-9 col-lg-10">
                      <div className="text-center mb-6">
                          <h2 className="title">{t("terms_of_service.title")}</h2>
                          <ul className="list list-row gx-2">
                              <li>
                                  <div className="sub-text fs-5">{t("terms_of_service.last_updated")}</div>
                              </li>
                          </ul>
                      </div>
                      <div className="block-typo">
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intro")}</p>

                            <h3>{t("terms_of_service.alliance_com_info.title")}</h3>
                            <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.alliance_com_info.content")}</p>

                        <h3>{t("terms_of_service.definitions.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.definitions.paragraph")}</p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.subscription.title")}</b>
                            {t("terms_of_service.definitions.subscription.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.access.title")}</b> 
                            {t("terms_of_service.definitions.access.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.admin.title")}</b> 
                            {t("terms_of_service.definitions.admin.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.service_features.title")}</b>
                            {t("terms_of_service.definitions.service_features.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.chatbot.title")}</b>
                            {t("terms_of_service.definitions.chatbot.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.client.title")}</b>
                            {t("terms_of_service.definitions.client.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.customer_account.title")}</b>
                            {t("terms_of_service.definitions.customer_account.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.terms_of_use.title")}</b>
                            {t("terms_of_service.definitions.terms_of_use.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.content.title")}</b>
                            {t("terms_of_service.definitions.content.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.contract.title")}</b>
                            {t("terms_of_service.definitions.contract.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.effective_date.title")}</b>
                            {t("terms_of_service.definitions.effective_date.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.documentation.title")}</b>
                            {t("terms_of_service.definitions.documentation.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.client_data.title")}</b>
                            {t("terms_of_service.definitions.client_data.definition")}
                        </p>

                        
                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.login_data.title")}</b>
                            {t("terms_of_service.definitions.login_data.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.duration.title")}</b>
                            {t("terms_of_service.definitions.duration.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.subscription_form.title")}</b>
                            {t("terms_of_service.definitions.subscription_form.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.service_fees.title")}</b>
                            {t("terms_of_service.definitions.service_fees.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.additional_user_fees.title")}</b>
                            {t("terms_of_service.definitions.additional_user_fees.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.group.title")}</b>
                            {t("terms_of_service.definitions.group.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.business_hours.title")}</b>
                            {t("terms_of_service.definitions.business_hours.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.confidential_information.title")}</b>
                            {t("terms_of_service.definitions.confidential_information.definition")}
                        </p>

                        <p style={{whiteSpace:'pre-wrap'}}>
                            <b>{t("terms_of_service.definitions.business_days.title")}</b>
                            {t("terms_of_service.definitions.business_days.definition")}
                        </p>

                        <h3>{t("terms_of_service.duration.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.duration.content")}</p>

                        <h3>{t("terms_of_service.access_ai_service.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.4")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.5")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.6")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.7")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.8")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.9")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.access_ai_service.content.10")}</p>

                        <h3>{t("terms_of_service.service_levels.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.service_levels.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.service_levels.content.2")}</p>


                        <h3>{t("terms_of_service.client_obligations.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.4")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.5")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.6")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.7")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.8")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.9")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.10")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.11")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.12")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.13")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.14")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.15")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.16")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.17")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.18")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.19")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.20")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.21")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.22")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.client_obligations.content.23")}</p>

                        <h3>{t("terms_of_service.intellectual_property_rights.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.4")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.5")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.6")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.7")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.8")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.9")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.10")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.intellectual_property_rights.content.11")}</p>

                        <h3>{t("terms_of_service.confidentiality.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.4")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.5")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.6")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.7")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.8")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.9")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.10")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.11")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.confidentiality.content.12")}</p>

                        <h3>{t("terms_of_service.data_protection.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.data_protection.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.data_protection.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.data_protection.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.data_protection.content.4")}</p>

                        <h3>{t("terms_of_service.fees_and_payment.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.fees_and_payment.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.fees_and_payment.content.2")}</p>

                        <h3>{t("terms_of_service.suspension_and_termination.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.suspension_and_termination.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.suspension_and_termination.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.suspension_and_termination.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.suspension_and_termination.content.4")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.suspension_and_termination.content.5")}</p>

                        <h3>{t("terms_of_service.warranty.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.warranty.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.warranty.content.2")}</p>

                        <h3>{t("terms_of_service.eviction_warranty.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.eviction_warranty.content")}</p>

                        <h3>{t("terms_of_service.liability.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.liability.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.liability.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.liability.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.liability.content.4")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.liability.content.5")}</p>

                        <h3>{t("terms_of_service.general.title")}</h3>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.general.content.1")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.general.content.2")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.general.content.3")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.general.content.4")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.general.content.5")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.general.content.6")}</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{t("terms_of_service.general.content.7")}</p>

                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
  )
}

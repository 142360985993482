import React, { useEffect, useState } from 'react';
import Section from '../../components/UI/Section/Section';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import { signupService } from '../../services/auth.service';
import { Link } from 'react-router-dom';
import AlertBox from '../../components/UI/AlertBox/AlertBox';
import LOGO_IMG from '../../assets/images/logo-no-text.png'
import { useTranslation } from 'react-i18next';
import { useAsyncLoader } from '../../hooks/useAsyncLoader';
import { generateUniqueId, getPasswordStrengh, isPasswordsMatch, isWeakPassword } from '../../utils';
import { COUNTRIES } from '../../constants';

export default function Signup() {
  const dispatch: AppDispatch = useDispatch();
  const { t, i18n} = useTranslation();
  const {handler, isLoading} = useAsyncLoader();
  const [isParticular, setIsParticular] = useState(false);
  const [hasAllianceComAccount, setHasAllianceComAccount] = useState(false);
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle password visibility

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Step 1: User Info
  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
    gender: '',
    phone_number: '',
  });

  // Step 2: Company Info
  const [companyInfo, setCompanyInfo] = useState({
    company_name: '',
    alliance_com_number:'',
    vat_number: '',
  });

  // Step 3: Billing Address
  const [billingAddress, setBillingAddress] = useState({
    billing_address: '',
    billing_address_complement: '',
    postal_code: '',
    city: '',
    country: '',
  });

  const {length, hasLowercase, hasUppercase, hasNumber, hasSpecialCharacter, nbValid} = getPasswordStrengh(userInfo.password)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
  
    // Check if the input is for the phone_number field
    if (name === 'phone_number') {
      // Use a regular expression to remove non-numeric characters
      const numericValue = value.replace(/\D/g, '');
  
      // Update the state with the cleaned numeric value
      setUserInfo({ ...userInfo, [name]: numericValue });
    } else {
      // For other fields, update the state normally
      if (step === 1) {
        setUserInfo({ ...userInfo, [name]: value });
      } else if (step === 2) {
        setCompanyInfo({ ...companyInfo, [name]: value });
      } else if (step === 3) {
        setBillingAddress({ ...billingAddress, [name]: value }); 
      }
    }
  };  

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission for each step or final submission
    if (step === 1) {
      handleNextStep(); // Move to Step 2
    } else if (step === 2) {
      handleNextStep(); // Move to Step 3
    } else if (step === 3) {
      // Perform final form submission here
      const formData = {
        ...userInfo,
        ...companyInfo,
        ...billingAddress,
      };
      handler(()=>dispatch(signupService(formData)))
      .then(()=>{
        setIsSuccess(true);
        setMessage(`${t("signup.msg-account-created")}`);
        setIsVisible(true);
      })
      .catch((error)=>{
        setIsSuccess(false);
        setIsVisible(true);
        setMessage(error);
      })
    }
  };

  const renderFormFields = () => {
    if (step === 1) {
      // Render User Info fields
      return (
        <>
          {/* User Info Fields */}
          <h4 className="title">{t("signup.step1")}</h4>
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label className="form-label" htmlFor="firstname">{t("signup.fname")}</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={userInfo.first_name}
                  onChange={handleChange}
                  className="form-control form-control-lg"
                  placeholder={t("signup.fname-placeholder")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label className="form-label" htmlFor="lastname">{t("signup.lname")}</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={userInfo.last_name}
                  onChange={handleChange}
                  className="form-control form-control-lg"
                  placeholder={t("signup.lname-placeholder")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label className="form-label" htmlFor="gender">{t("signup.sexe")}</label>
              <select
                id="gender"
                name="gender"
                onChange={handleChange}
                className="form-control form-control-lg"
                defaultValue=''
                >
                <option value=''>{t("signup.sexe-placeholder")}</option>
                <option value={'Male'}>{t("signup.sexe-male")}</option>
                <option value={'Female'}>{t("signup.sexe-female")}</option>
                <option value={'Other'}>{t("signup.sexe-other")}</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label className="form-label" htmlFor="telNumber">{t("signup.phone")}</label>
              <div className="form-control-wrap">
                <input
                  type="tel"
                  id="phone_number" 
                  name="phone_number"
                  value={userInfo.phone_number}
                  className="form-control form-control-lg"
                  placeholder={t("signup.phone-placeholder")}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="emailaddress">{t("signup.email")} </label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  id="email" 
                  name="email"
                  value={userInfo.email}
                  onChange={handleChange}
                  className="form-control form-control-lg"
                  placeholder={t("signup.email-placeholder")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="toggle-password">{t("signup.pwd")}</label>
              <div className="form-control-wrap">
                <Link  to="#" onClick={togglePasswordVisibility} className={`form-control-icon end password-toggle ${showPassword ? ' is-shown' : ''} `}  title="Toggle show/hide password">
                  <em className="on icon ni ni-eye text-base"></em>
                  <em className="off icon ni ni-eye-off text-base"></em>
                </Link>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password" 
                  name="password"
                  value={userInfo.password}
                  onChange={handleChange}
                  className={`form-control form-control-lg  ${ !!userInfo.password && nbValid>2 && nbValid <4 ? 'border border-warning' : !!userInfo.password && nbValid <=2 ? 'border border-danger' : '' }`}
                  placeholder={t("signup.pwd-placeholder")}
                  minLength={8}
                  required
                />
              </div>

              {  !!userInfo.password && nbValid==4 ? 
                <label className='text-success mx-2 mt-2'>{t("signup.pwd-strong")}</label>
                : !!userInfo.password && nbValid>2 ? 
                <label className='text-warning mx-2 mt-2'>{t("signup.pwd-medium")}</label>
                : !!userInfo.password && nbValid <=2 ? 
                <label className='text-danger mx-2 mt-2'>{t("signup.pwd-weak")}</label>
                :
                null
              }
              <ul className="list  p-2">

                <li className={`smaller ${hasLowercase && hasUppercase? "text-success" : ""}`}>
                  <em className="icon ni ni-check-circle-fill"></em>
                  <span className="low-upper-case">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                  </span>{t("signup.pwd-rules.1")}
                </li>

                <li className={`mt-1 smaller ${hasNumber? "text-success" : ""}`}>
                  <em className="icon ni ni-check-circle-fill "></em>
                  <span className="one-number">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                  </span>{t("signup.pwd-rules.2")}
                </li>

                <li className={`mt-1 smaller ${hasSpecialCharacter? "text-success" : ""}`}>
                  <em className="icon ni ni-check-circle-fill "></em>
                  <span className="one-special-char">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                  </span>{t("signup.pwd-rules.3")}
                </li>

                <li className={`mt-1 smaller ${length? "text-success" : ""}`}>
                  <em className="icon ni ni-check-circle-fill"></em>
                  <span className="">
                    <i className="fa fa-file-text" aria-hidden="true"></i>
                  </span>{t("signup.pwd-rules.4")}
                </li>

              </ul>

            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="toggle-password-confirm">{t("signup.pwd-confirmation")}</label>
              <div className="form-control-wrap">
                <Link to="#" onClick={toggleConfirmPasswordVisibility} className={`form-control-icon end password-toggle ${showConfirmPassword ? ' is-shown' : ''} `}  title="Toggle show/hide password">
                  <em className="on icon ni ni-eye text-base"></em>
                  <em className="off icon ni ni-eye-off text-base"></em>
                </Link>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="toggle-password-confirm"
                  name="confirm_password"
                  value={userInfo.confirm_password}
                  onChange={handleChange}
                  className={`form-control form-control-lg ${!isPasswordsMatch(userInfo.password, userInfo.confirm_password)?'border border-danger':'' }`}
                  placeholder={t("signup.pwd-confirmation-placeholder")}
                  minLength={8}
                  required
                />
              </div>
              {!isPasswordsMatch(userInfo.password, userInfo.confirm_password)&&<label className='text-danger  mx-2 mt-2'>{t("signup.pwd-match")}</label>}
            </div>
          </div>
          <div className="col-12 text-center">
            <button  className="btn btn-success rounded-pill " type="button" onClick={handleNextStep} disabled={!isStepComplete(1)}>
            {t("buttons.next")}
            </button>
          </div>
        </>
      );
    } else if (step === 2) {
      // Render Company Info fields
      return (
        <>
          {/* Company Info Fields */}
          <h4 className="title">{t("signup.step2")}</h4>
          <div className="col-12">
            <div className="form-group">
              <div className="form-control-wrap">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="particular-admin"
                    className="form-check-input"
                    checked={isParticular}
                    onChange={handleCompanyCheck}
                  />
                  <label className="form-check-label" htmlFor="particular-admin">        
                   {t("signup.individual")}
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="company-admin"
                    className="form-check-input"
                    checked={!isParticular}
                    onChange={handleCompanyCheck}
                  />
                  <label className="form-check-label" htmlFor="company-admin">
                    {t("signup.company")}
                  </label>
                </div>
              </div>
            </div>
          </div>
            {!isParticular && (
            <>
              <div className="col-12">
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="alliance_com_number"
                      name='alliance_com_number'
                      className="form-check-input"
                      checked={hasAllianceComAccount}
                      onChange={handleAllianceComCheck}
                    />
                    <label className="form-check-label" htmlFor="alliance-com-account">
                      {t("signup.has-alliance-number")}              
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="company-name">
                    {t("signup.social-reason")}                 
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      value={companyInfo.company_name}
                      className="form-control form-control-lg"
                      placeholder={t("signup.social-reason-placeholder")}   
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </>
            )}
            {!isParticular && hasAllianceComAccount && (  
              <div className="col-12">
                  <div className="form-group">
                      <label className="form-label" htmlFor="alliance_com_number">
                        {t("signup.alliance-number")}                      
                      </label>
                    <div className="form-control-wrap">
                      <input
                      type="text"
                      name="alliance_com_number"
                      id="alliance_com_number"
                      className="form-control form-control-lg"
                      placeholder={t("signup.alliance-number-placeholder")}
                      value={companyInfo.alliance_com_number}
                      onChange={handleChange}
                      required
                      />
                    </div>
                  </div>
              </div>
            )}
            {!isParticular && !hasAllianceComAccount && (
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="tva-number">
                  {t("signup.vat")}                      
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      name="vat_number"
                      id="vat_number"
                      className="form-control form-control-lg"
                      placeholder={t("signup.vat-placeholder")}   
                      value={companyInfo.vat_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          <div className='col-12 text-center'>
            <button className="btn btn-danger rounded-pill m-1" type="button" onClick={handlePrevStep}>
             {t("buttons.previous")}   
            </button>
            <button className="btn btn-success rounded-pill m-1" type="button" onClick={handleNextStep}>
             {t("buttons.next")}   
            </button>
          </div>
        </>
      );
    } else if (step === 3) {
      // Render Billing Address fields
      return (
        <>
          {/* Billing Address Fields */}
          <h4 className="title">{t("signup.step3")}</h4>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="billing-address">{t("signup.billing-address")}</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="billing_address"
                  id="billing_address"
                  value={billingAddress.billing_address}
                  className="form-control form-control-lg"
                  placeholder={t("signup.billing-address-placeholder")}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="billing-address-complement">{t("signup.additional-address")}</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="billing_address_complement"
                  id="billing_address_complement"
                  value={billingAddress.billing_address_complement}
                  className="form-control form-control-lg"
                  onChange={handleChange}
                  placeholder={t("signup.additional-address-placeholder")}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
          <div className="form-group">
              <label className="form-label" htmlFor="gender">{t("signup.country")}</label>
              <select
                id="country"
                name="country"
                onChange={handleChange}
                className="form-control form-control-lg"
                value={billingAddress.country}
                >
                  <option value=''>{t("signup.country-placeholder")}</option>
                  {COUNTRIES[i18n.language].map(country=><option key={generateUniqueId()} value={country}>{country} </option>)}
              </select>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label className="form-label" htmlFor="billing-city">{t("signup.city")}</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={billingAddress.city}
                  className="form-control form-control-lg"
                  placeholder={t("signup.city-placeholder")}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label className="form-label" htmlFor="billing-zip">{t("signup.postal-code")}</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="postal_code"
                  id="postal_code"
                  value={billingAddress.postal_code}
                  onChange={handleChange}                   
                  className="form-control form-control-lg"
                  placeholder={t("signup.postal-code-placeholder")}
                  required
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <div className="form-check form-check-sm">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="iAgree"
                  required
                />
                <label className="form-check-label small" htmlFor="iAgree">
                    {t("signup.accept-terms")}<Link to="/privacy">{t("signup.privacy")}</Link> {t("signup.and")}
                  <Link to="/terms">{t("signup.conditions")}</Link>
                </label>
              </div>
            </div>
          </div>
          <div className='col-12 text-center'>
            <button className="btn btn-danger rounded-pill  m-1" type="button" onClick={handlePrevStep}>
              {t("buttons.previous")}
            </button>
            <button className="btn btn-success rounded-pill  m-1" type="submit" disabled={!isStepComplete(3)}>
              {t("buttons.signup")}
            </button>
          </div>
        </>
      );
    }
  };

  // Function to check if all fields in a step are filled
  const isStepComplete = (stepNumber:number) => {
    if (stepNumber === 1) {
      const { first_name, last_name, email, password, confirm_password, phone_number, gender } = userInfo;
      return !!first_name && !!last_name && !!email && !!password && !!confirm_password && !!phone_number && !!gender && password==confirm_password && !isWeakPassword(password);
    } else if (stepNumber === 3) {
      const { billing_address, postal_code, city, country } = billingAddress;
      return !!billing_address && !!postal_code && !!city && !!country;
    }
    return true; // For step 2 (optional), always return true
  };


  const handleCompanyCheck = () => {
    setIsParticular(!isParticular);
    setHasAllianceComAccount(false);
    if (isParticular){
      setCompanyInfo(({
        company_name: '',
        alliance_com_number:'',
        vat_number: '',
      }));
    }
  };

  const handleAllianceComCheck = () => {
    setHasAllianceComAccount(!hasAllianceComAccount);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
    setIsVisible(false);
    }, 4000); // Hide the error message after 5 seconds
  
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);


  
  return (
    <>
      { !isSuccess &&   
        <Section classNames="section has-mask pt-0">
          <div className="my-auto">
            <div className="container">
              <div className="section-head pb-5">
                <div className="row justify-content-center text-center">
                  <div className="col-md-7 col-lg-6 col-xl-5">
                    <Link to={'/'} className='allianceLogoLink' >
                      <img className="h-5rem mb-3 " src={LOGO_IMG}  alt=""/>
                    </Link>   
                    <h4 className="title">Alliance-Com </h4>
                    <h3 className="title">{t("signup.title")} </h3>
                  </div>
                </div>
              </div>
              <div className="section-content">
                <div className="row g-gs justify-content-center">
                  <div className="col-lg-8 col-md-10">
                    <div className="card border-0 shadow-sm rounded-4">
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                        <div className="row g-3">
                          { renderFormFields() }
                          </div>
                        </form>
                        <p className="text-center mt-4">
                          <span className='me-2'>{t("signup.have-account")}</span><Link to="/signin" className=" link link-primary">{t("signup.link")}</Link>
                        </p>
                      </div>
                    </div>
                    <AlertBox color='danger' message={message} visibility={isVisible}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      }
        { isSuccess &&   
          <Section classNames="section has-mask ">
            <div className="my-auto">
              <div className="container">
                <div className="section-head pb-5">
                  <div className="row justify-content-center text-center">
                    <div className="row justify-content-center text-center mb-8">
                      <div className="col-md-8 col-lg-10 col-xl-12">
                        <Link to={'/'} className='allianceLogoLink' >
                          <img className="h-5rem mb-3 " src={LOGO_IMG}  alt=""/>
                        </Link>   
                        <h4 className="title text-info">AllianceCom</h4>
                        <h3 className="title mt-8">{t("signup.msg-ready")}</h3>
                      </div>
                    </div>
                    <div className="section-content text-center">
                      <div className="row g-gs justify-content-center">
                        <div className="col-lg-6 col-md-12">
                          <Link 
                            className="btn btn-lg btn-primary rounded-pill mb-3" 
                            to="/signin">
                            <span>{t("buttons.connect-now")}</span>
                            <em className="icon ni ni-arrow-long-right"></em>
                          </Link>
                        </div>
                        <AlertBox color='success' message={message} visibility={isVisible}/>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </Section>
        }
    </>
  );
}
